import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some, isEqual } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateRohstoff,
  getRohstoffByID,
  deleteRohstoff,
  checkRohstoffe,
  getRohstoffkategorien,
  getVistPermissions,
} from '../../../actions';

import { UnsavedChangesWarning, convertNumbersToStrings } from 'helpers';

import RohstoffForm from './RohstoffForm';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RohstoffEdit = ({
  pathname,
  rohstoff,
  getRohstoffByID,
  checkRohstoffe,
  updateRohstoff,
  deleteRohstoff,
  getRohstoffkategorien,
  rohstoffkategorien,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const [formData, setFormData] = useState({
    rohstoffnummer: '',
    bezeichnung: '',
    user_id: '',
    brennwert_kj: '',
    brennwert_kcal: '',
    eiweiss: '',
    kohlenhydrate: '',
    zucker: '',
    fett: '',
    ges_fettsaeuren: '',
    ballaststoffe: '',
    natrium: '',
    staerke: '',
    zusatzinformationen: '',
    basis_euro: '',
    status: '',
  });
  const [initialFormData, setInitialFormData] = useState({});
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isRohstoffnummerDuplicate, setIsRohstoffnummerDuplicate] = useState(
    false,
  );
  const [isBezeichnungDuplicate, setIsBezeichnungDuplicate] = useState(false);
  const [rohstoffnummerSuggestions, setRohstoffnummerSuggestions] = useState(
    [],
  );

  const [backURL, setBackURL] = useState(null);
  const isSubmitDisabled =
    isRohstoffnummerDuplicate ||
    isBezeichnungDuplicate ||
    formData.rohstoffnummer === '' ||
    formData.bezeichnung === '';
  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 4, 6];
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [modified, setModified] = useState(false);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    setBackURL(`/controlpanel/rezeptdatenbank/rohstoffe/details?id=${id}`);
    getRohstoffByID(id);
    getRohstoffkategorien({ showAll: true });
  }, [getRohstoffByID, getRohstoffkategorien]);

  useEffect(() => {
    if (rohstoff) {
      const rohstoffdata = {
        rohstoffnummer: rohstoff.rohstoffnummer,
        bezeichnung: rohstoff.bezeichnung,
        brennwert_kj: rohstoff.brennwert_kj,
        brennwert_kcal: rohstoff.brennwert_kcal,
        eiweiss: rohstoff.eiweiss,
        kohlenhydrate: rohstoff.kohlenhydrate,
        zucker: rohstoff.zucker,
        fett: rohstoff.fett,
        ges_fettsaeuren: rohstoff.ges_fettsaeuren,
        ballaststoffe: rohstoff.ballaststoffe,
        natrium: rohstoff.natrium,
        staerke: rohstoff.staerke,
        zusatzinformationen: rohstoff.zusatzinformationen,
        basis_euro: rohstoff.basis_euro,
        status: rohstoff.status,
        kategorien: rohstoff.kategorien,
      };
      setFormData(rohstoffdata);
      setInitialFormData(convertNumbersToStrings(rohstoffdata));
    }
  }, [rohstoff]);

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (!isEqual(convertNumbersToStrings(formData), initialFormData)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [formData]);

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'rohstoffnummer') {
      checkRohstoffe(name, value)
        .then((result) => {
          if (
            result.value_exists === true &&
            value !== rohstoff.rohstoffnummer.toString()
          ) {
            setIsRohstoffnummerDuplicate(true);
          } else {
            setIsRohstoffnummerDuplicate(false);
          }
          if (result.suggested_values) {
            setRohstoffnummerSuggestions(result.suggested_values);
          } else {
            setRohstoffnummerSuggestions([]);
          }
        })
        .catch((error) => {
          setError(error.name);
        });
    }

    if (name === 'bezeichnung' && value !== rohstoff.bezeichnung) {
      checkRohstoffe(name, value)
        .then((result) => {
          if (result === true) {
            setIsBezeichnungDuplicate(true);
          } else {
            setIsBezeichnungDuplicate(false);
          }
        })
        .catch((error) => {
          setError(error.name);
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await updateRohstoff(id, formData);
      history.push(`/controlpanel/rezeptdatenbank/rohstoffe/details?id=${id}`);
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error.name);
    }
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteRohstoff(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error.name);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben den Rohstoff bearbeitet. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Helmet title="Rohstoff Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>{rohstoff && rohstoff.bezeichnung} </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={false}
                  handleDelete={handleDelete}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={backURL}
                  showDeleteButton={showDeleteButton}
                  typeString={'den Rohstoff'}
                />
              </div>
            </div>
            <div className="rezeptdatenbank header"></div>
          </Segment>
          <Segment>
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Rohstoff wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/rohstoffe"
                >
                  Zurück zur Rohstoff-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                {error && (
                  <div className="error-message">
                    <h2>{error}</h2>
                  </div>
                )}
                <div className="rdb add form rohstoffe">
                  <RohstoffForm
                    isBezeichnungDuplicate={isBezeichnungDuplicate}
                    isRohstoffnummerDuplicate={isRohstoffnummerDuplicate}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    handleKategorienChange={handleKategorienChange}
                    kategorien={rohstoffkategorien}
                    selectedKategorien={formData.kategorien}
                    rohstoffnummer_suggestions={rohstoffnummerSuggestions}
                  />
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RohstoffEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  rohstoff: PropTypes.object.isRequired,
  rohstoffe: PropTypes.array.isRequired,
  updateRohstoff: PropTypes.func.isRequired,
  getRohstoffByID: PropTypes.func.isRequired,
  deleteRohstoff: PropTypes.func.isRequired,
  checkRohstoffe: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  getRohstoffkategorien: PropTypes.func.isRequired,
  rohstoffkategorien: PropTypes.array.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      rohstoff: state.rohstoffByID.data,
      rohstoffkategorien: state.rohstoffkategorien.data.rohstoffkategorien,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      updateRohstoff,
      getRohstoffByID,
      checkRohstoffe,
      deleteRohstoff,
      getRohstoffkategorien,
      getVistPermissions,
    },
  ),
)(RohstoffEdit);
