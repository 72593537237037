import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateRezeptkategorie,
  getRezeptkategorieByID,
  getRezeptkategorien,
  deleteRezeptkategorie,
  getVistPermissions,
} from '../../../actions';

import ButtonArea from '../ButtonArea';

import RezeptkategorieForm from './RezeptkategorieForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RezeptkategorieEdit = ({ intl }) => {
  const [formData, setFormData] = useState({
    name: '',
    pid: '',
    id: undefined,
    level: 0,
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [
    isRezeptkategorieNameDuplicate,
    setIsRezeptkategorieNameDuplicate,
  ] = useState(false);
  const isSubmitDisabled = formData.name === '';

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const pathname = useSelector((state) => state.router.location.pathname);
  const rezeptkategorie = useSelector(
    (state) => state.rezeptkategorieByID.data,
  );
  const rezeptkategorien = useSelector(
    (state) => state.rezeptkategorien.data.rezeptkategorien,
  );
  const token = useSelector((state) => state.userSession.token);
  const permissions = useSelector((state) => state.permissions.data || null);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      dispatch(getVistPermissions())
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    dispatch(getRezeptkategorien({ getAll: true }));
    dispatch(getRezeptkategorieByID(id));
  }, [dispatch]);

  useEffect(() => {
    if (rezeptkategorie) {
      setFormData({
        name: rezeptkategorie.name,
        id: rezeptkategorie.id,
        pid: rezeptkategorie.pid,
        level: rezeptkategorie.level,
      });
    }
  }, [rezeptkategorie]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'name' && value !== rezeptkategorie.name) {
      const isDuplicate = rezeptkategorien.some(
        (rezeptkategorie) => rezeptkategorie.name === value,
      );
      setIsRezeptkategorieNameDuplicate(isDuplicate);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await dispatch(updateRezeptkategorie(id, formData));
      history.push(`/controlpanel/rezeptdatenbank/rezeptkategorien`);
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error);
    }
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await dispatch(deleteRezeptkategorie(id));
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <Helmet title="Rezeptkategorie Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>{rezeptkategorie && rezeptkategorie.name} </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={'/controlpanel/rezeptdatenbank/rezeptkategorien'}
                  typeString={'die Rezeptkategorie'}
                />
              </div>
            </div>
            <div className="rezeptdatenbank header"></div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Rezeptkategorie wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/rezeptkategorien"
                >
                  Zurück zur Rezeptkategorien-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                <div className="rdb edit form rezeptkategorien">
                  <RezeptkategorieForm
                    formData={formData}
                    isRezeptkategorieNameDuplicate={
                      isRezeptkategorieNameDuplicate
                    }
                    handleInputChange={handleInputChange}
                    kategorien={rezeptkategorien}
                  />
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RezeptkategorieEdit.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RezeptkategorieEdit);
