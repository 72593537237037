import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message, Tab } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getRezeptByID,
  deleteRezept,
  getRezeptNutrientsByID,
  getRohstoffKostenByID,
  getVerkaufsinformationByID,
  createVerkaufsinformation,
  getVistPermissions,
} from '../../../actions';
import NavHeader from '../NavHeader';
import ButtonArea from '../ButtonArea';
import NextPreviousButtons from '../NextPreviousButtons';
import NutrientDetails from './Details/NutrientDetails';
import ResouceCosts from './Details/ResourceCosts';
import GeneralDetails from './Details/GeneralDetails';
import VerkaufsinformationDetails from './Details/Verkaufsinformation/VerkaufsinformationDetails';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RezepteDetails = ({
  pathname,
  rezept,
  rezeptNutrients,
  rohstoffKosten,
  getRezeptNutrientsByID,
  getRohstoffKostenByID,
  getRezeptByID,
  deleteRezept,
  getVerkaufsinformationByID,
  verkaufsinformation,
  createVerkaufsinformation,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  rezepte_list,
  batching_params,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const editURL =
    activeIndex === 3
      ? '/controlpanel/rezeptdatenbank/rezepte/verkaufsinformation/'
      : '';
  const editName = activeIndex === 3 ? 'Verkaufsinformation' : 'Rezept';
  const [numberOfPieces, setNumberOfPieces] = useState(1);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 12, 13, 14, 15, 2, 4, 6];
  // Define the allowed user groups for editing Produkte / Gebinde and adding new gebinde
  const showEditButton = [1, 13, 14, 2, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      setIsClient(true);
      getRohstoffKostenByID(id, numberOfPieces);
      getRezeptByID(id);
      getRezeptNutrientsByID(id);
      getVerkaufsinformationByID(id);
    }
    // eslint-disable-next-line
  }, [getRezeptByID, getRezeptNutrientsByID, id]);

  useEffect(() => {
    if (id) {
      getRohstoffKostenByID(id, numberOfPieces);
    }
  }, [numberOfPieces, id, getRohstoffKostenByID]);

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteRezept(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error);
    }
  };

  const addVerkaufsinformation = () => {
    if (id) {
      const verkaufsinformation_data = {
        rezepte_id: id,
        deleted: 0,
        hauptbestandteile: '',
        merkmale: '',
        lagerung: '',
        verzehrtipps: '',
        hinweise: '',
      };

      createVerkaufsinformation(verkaufsinformation_data)
        .then((result) => {
          const verkaufsinformation_id = result.id;
          if (verkaufsinformation_id) {
            history.push(
              `/controlpanel/rezeptdatenbank/rezepte/verkaufsinformation/edit?id=${id}`,
            );
          } else {
            setError(
              error.name +
                ': Es ist etwas schiefgelaufen, Verkaufsinformation wurde nicht angelegt.',
            );
          }
        })
        .catch((error) => {
          setError(
            error.name +
              ': Es ist etwas schiefgelaufen, Verkaufsinformation wurde nicht angelegt',
          );
          setErrorDetail(error.name + ': ' + error.message);
        });
    }
  };

  const panes = [
    {
      menuItem: 'Rezept',
      render: () => (
        <Tab.Pane>{rezept && <GeneralDetails rezept={rezept} />}</Tab.Pane>
      ),
    },
    {
      menuItem: 'Nährwerte',
      render: () => (
        <Tab.Pane>
          {rezeptNutrients && (
            <NutrientDetails rezeptNutrients={rezeptNutrients} />
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Rohstoffkosten',
      render: () => (
        <Tab.Pane>
          {rohstoffKosten && (
            <ResouceCosts
              rohstoffKosten={rohstoffKosten}
              numberOfPieces={numberOfPieces}
              setNumberOfPieces={setNumberOfPieces}
            />
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Verkaufsinformation',
      render: () => (
        <Tab.Pane>
          {verkaufsinformation.id ? (
            <VerkaufsinformationDetails
              verkaufsinformation={verkaufsinformation}
            />
          ) : (
            <div className="sales-info-add">
              <h2>Noch keine Verkaufsinformation vorhanden</h2>
              <Button onClick={addVerkaufsinformation}>
                Verkaufsinformation Anlegen
              </Button>
            </div>
          )}
        </Tab.Pane>
      ),
    },
  ];

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <Helmet title="Rezepte" />
      <Container className="controlpanel">
        <NavHeader permissions={permissions} />
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {rezept && rezept.name}</h1>
                <h2>{rezept && rezept.verkehrsbezeichnung}</h2>
              </div>
              <div className="button-container">
                <NextPreviousButtons
                  currentItem={id}
                  itemlist={rezepte_list.rezepte}
                  totalPages={rezepte_list.total_pages}
                  batching_params={batching_params}
                />
                <ButtonArea
                  isDetails={true}
                  id={rezept.id}
                  editURL={editURL}
                  editName={editName}
                  handleDelete={handleDelete}
                  backURL={`/controlpanel/rezeptdatenbank/rezepte`}
                  showDeleteButton={showDeleteButton}
                  showEditButton={showEditButton}
                  typeString={'das Rezept'}
                />
              </div>
            </div>
          </Segment>
          {error && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>
                {error.message} {errorDetail}
              </p>
            </Message>
          )}
          {deleteSuccess ? (
            <Message positive>
              <Message.Header>Rezept wurde gelöscht</Message.Header>
              <Button
                primary
                as={Link}
                to="/controlpanel/rezeptdatenbank/rezepte"
              >
                Zurück zur Rezepte-Übersicht
              </Button>
            </Message>
          ) : (
            <>
              {rezept && (
                <Tab
                  menu={{ secondary: true, pointing: true }}
                  panes={panes}
                  activeIndex={activeIndex}
                  onTabChange={(e, { activeIndex }) => {
                    e.preventDefault();
                    setActiveIndex(activeIndex);
                  }}
                />
              )}
            </>
          )}
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RezepteDetails.propTypes = {
  pathname: PropTypes.string.isRequired,
  rezept: PropTypes.object.isRequired,
  rezeptNutrients: PropTypes.object.isRequired,
  getRezeptByID: PropTypes.func.isRequired,
  deleteRezept: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  verkaufsinformation: PropTypes.object.isRequired,
  getVerkaufsinformationByID: PropTypes.func.isRequired,
  createVerkaufsinformation: PropTypes.func.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      rezepte_list: state.rezepte.data,
      batching_params: state.batchingParameters,
      pathname: state.router.location.pathname,
      rezept: state.rezeptByID.data,
      rezeptNutrients: state.rezeptNutrients.data,
      rohstoffKosten: state.rohstoffKosten.data,
      verkaufsinformation: state.verkaufsinformationByID.data,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      getRezeptByID,
      deleteRezept,
      getRezeptNutrientsByID,
      getRohstoffKostenByID,
      getVerkaufsinformationByID,
      createVerkaufsinformation,
      getVistPermissions,
    },
  ),
)(RezepteDetails);
