import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { UnsavedChangesWarning, convertNumbersToStrings } from 'helpers';

import {
  createRezept,
  checkRezepte,
  getBezuege,
  getZutatengruppen,
  getRohstoffeNames,
  getProdukteNames,
  getGrundrezepte,
  createZutat,
  getRezeptkategorien,
  getRezeptgruppen,
  getVistPermissions,
} from '../../../actions';
import ButtonArea from '../ButtonArea';

import RezeptForm from './RezeptForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RezeptCreate = ({
  pathname,
  createRezept,
  createZutat,
  getBezuege,
  bezuege,
  getZutatengruppen,
  zutatengruppen,
  getRohstoffeNames,
  rohstoffeNames,
  getProdukteNames,
  produkteNames,
  getGrundrezepte,
  grundrezepte,
  getRezeptkategorien,
  rezeptkategorien,
  rezeptgruppen,
  getRezeptgruppen,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const dispatch = useDispatch();
  const emptyFormData = {
    rezeptcode: '',
    variante: 0,
    bezuege_id: '',
    kopie_von: '', //needed?
    mehrzweckfeld: '', //needed?
    name: '',
    name_etikett: '',
    preis: '',
    rezeptstatus: 3,
    verkehrsbezeichnung: '',
    zusatz: '',
    intern_extern: true,
    aktuell: '',
    knetzeit: '',
    ruehrzeit: '',
    teigtemperatur: '',
    massentemperatur: '',
    teigruhe: '',
    teigausbeute: '',
    quellzeit: '',
    pressen_zwischengare: '',
    backtemperatur: '',
    siedefetttemperatur: '',
    kerntemperatur: '',
    backzeit: '',
    herstellungstext: '<p class="tile text"></p>',
    stueckzahl: 1,
    teigeinlage: '',
    pressengewicht: '',
    masseneinwaage: '',
    backverlust: '',
    kennung: '',
    etikett: '',
    kategorien: [],
    gruppen: [],
  };
  const [formData, setFormData] = useState(emptyFormData);

  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [zutaten, setZutaten] = useState([]);
  const [isRezeptnummerDuplicate, setIsRezeptNummerDuplicate] = useState(false);
  const [isIncompleteZutat, setIsIncompleteZutat] = useState(false);
  const isSubmitDisabled =
    isRezeptnummerDuplicate ||
    formData.name === '' ||
    formData.verkehrsbezeichnung === '' ||
    formData.kennung === '' ||
    formData.rezeptcode === '' ||
    formData.variante === '' ||
    formData.bezuege_id === '' ||
    isIncompleteZutat;

  // rezepteCheck action
  const rezepteCheck = useSelector((state) => state.rezepteCheck);

  // assemble rezeptnummer from formdata
  const formData_rezeptnummer =
    formData.kennung + '-' + formData.rezeptcode + '-' + formData.variante;

  // last edited field
  const [lastField, setLastField] = useState('');

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 4, 6];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [modified, setModified] = useState(false);
  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsClient(true);
    getBezuege({ getAll: true });
    getZutatengruppen({ getAll: true });
    getRohstoffeNames();
    getProdukteNames();
    getGrundrezepte();
    getRezeptkategorien({ getAll: true });
    getRezeptgruppen({ getAll: true });
    // eslint-disable-next-line
  }, []);

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (
      !isEqual(
        convertNumbersToStrings(formData),
        convertNumbersToStrings(emptyFormData),
      )
    ) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [formData]);

  useEffect(() => {
    if (zutaten.length > 0) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [zutaten]);

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };

  const handleGruppenChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gruppen: value,
    }));
  };

  useEffect(() => {
    setIsIncompleteZutat(
      zutaten.some((zutat) => {
        return (
          (!zutat.rohstoffe_id &&
            !zutat.produkte_id &&
            !zutat.grundrezepte_id) ||
          (zutat.rohstoffe_id === '0' &&
            zutat.produkte_id === '0' &&
            zutat.grundrezepte_id === '0') ||
          isNaN(parseFloat(zutat.menge)) ||
          zutat.menge === 0 ||
          zutat.menge === '0' ||
          zutat.zutatengruppen_id === 0 ||
          zutat.zutatengruppen_id === '0'
        );
      }),
    );
  }, [zutaten]);

  // check for duplicates!
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLastField(name);

    if (name === 'intern_extern') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value === 'intern',
      }));
    } else if (name === 'kennung') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    } else if (name === 'rezeptcode') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.replace(/\D/g, ''),
      }));
    } else if (name === 'variante') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: Math.min(parseInt(value), 99),
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if (name === 'kennung' || name === 'rezeptcode' || name === 'variante') {
      let kennung = formData.kennung;
      let rezeptcode = formData.rezeptcode;
      let variante = formData.variante;

      if (name === 'kennung') {
        kennung = value;
      } else if (name === 'rezeptcode') {
        rezeptcode = value;
      } else if (name === 'variante') {
        variante = value;
      }

      let rezeptnummer = kennung + '-' + rezeptcode + '-' + (variante || '0');

      dispatch(checkRezepte('rezeptnummer', rezeptnummer, rezeptnummer));
      setIsRezeptNummerDuplicate(
        rezepteCheck.subrequests?.[rezeptnummer]?.data.value_exists,
      );
    }
  };

  // set variante to lowest available variante if rezeptcode is already in use
  // set isRezeptNummerDuplicate to true if the user changes to an already existing variant
  useEffect(() => {
    // only do this if there is data for the suggestions
    if (
      rezepteCheck.subrequests[formData_rezeptnummer]?.data?.suggested_variants
        ?.length > 0
    ) {
      // extract lowest possible variant
      let new_variante =
        rezepteCheck.subrequests[formData_rezeptnummer].data
          .suggested_variants[0];

      // extract if the current rezeptnummer is a duplicate
      let isDuplicate =
        rezepteCheck.subrequests[formData_rezeptnummer].data.value_exists;

      if (new_variante !== undefined && lastField === 'rezeptcode') {
        // set variant to lowest possible
        setFormData((prevFormData) => ({
          ...prevFormData,
          variante: new_variante,
        }));
        // set duplicate flag to false since the new one is not used
        setIsRezeptNummerDuplicate(false);
      } else if (isDuplicate !== undefined && lastField === 'variante') {
        // if user changes variante set duplicate flag to value from endpoint
        setIsRezeptNummerDuplicate(isDuplicate);
      }
    }
    // eslint-disable-next-line
  }, [rezepteCheck.subrequests]);

  const addZutat = (e) => {
    e.preventDefault();
    setZutaten([
      ...zutaten,
      {
        kategorie: 'rohstoff',
        rezepte_id: '0',
        rohstoffe_id: '0',
        produkte_id: '0',
        zutatengruppen_id: '0',
        grundrezepte_id: '0',
        sorting: zutaten.length,
        info: '',
        menge: '',
        backen: 1,
        select_zut_grundr: 1, // what does this do?
      },
    ]);
  };

  const removeZutat = (e, index) => {
    e.preventDefault();
    const updatedZutaten = zutaten.filter((_, i) => i !== index);
    updatedZutaten.forEach((zutat, index) => {
      zutat.sorting = index;
    });
    setZutaten(updatedZutaten);
  };

  const handleZutatChange = (e, index) => {
    const { name, value } = e.target;

    const updatedZutat = { ...zutaten[index] };

    if (e.type === 'click') {
      if (updatedZutat.backen !== 1) {
        updatedZutat.backen = 1;
      } else if (updatedZutat.backen === 1) {
        updatedZutat.backen = 0;
      }
    } else {
      updatedZutat[name] = value;
    }

    if (name === 'kategorie') {
      updatedZutat.rohstoffe_id = '0';
      updatedZutat.produkte_id = '0';
      updatedZutat.grundrezepte_id = '0';
    }
    const updatedZutatenList = zutaten.filter((_, i) => i !== index);
    updatedZutatenList.splice(updatedZutat.sorting, 0, updatedZutat);
    updatedZutatenList.forEach((zutat, index) => {
      zutat['sorting'] = index;
    });
    setZutaten(updatedZutatenList);
  };

  const handleSubmit = (e) => {
    setModified(false);
    e.preventDefault();

    // Remove fields with empty strings from the formData
    const cleanedFormData = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );
    createRezept(cleanedFormData)
      .then((result) => {
        const id = result.id;
        if (id) {
          zutaten.forEach((zutat) => {
            zutat.rezepte_id = id;
            createZutat(zutat).catch((error) => {
              setError(
                error.name +
                  ': Es ist etwas schiefgelaufen, Zutat wurde nicht angelegt',
              );
              setErrorDetail(error.name + ': ' + error.message);
            });
          });

          history.push(
            `/controlpanel/rezeptdatenbank/rezepte/details?id=${id}`,
          );
        } else {
          setError(
            error.name +
              ': Es ist etwas schiefgelaufen, Rezept wurde nicht angelegt.',
          );
        }
      })
      .catch((error) => {
        setError(
          error.name +
            ': Es ist etwas schiefgelaufen, Rezept wurde nicht angelegt',
        );
        setErrorDetail(error.name + ': ' + error.message);
      });
  };

  // Calculations:

  // Calculate gesamtgewicht_zutaten
  const gesamtgewicht_zutaten = zutaten?.reduce(
    (totalWeight, zutat) => totalWeight + parseFloat(zutat.menge || 0),
    0,
  );

  // Calculate gesamtgewicht_zutaten_not_baken
  const gesamtgewicht_zutaten_not_baken = zutaten
    ?.filter((zutat) => zutat.backen !== 1)
    ?.reduce(
      (totalWeight, zutat) => totalWeight + parseFloat(zutat.menge || 0),
      0,
    );

  // calculate stueckgewicht
  let stueckgewicht = 0;
  if (formData.stueckzahl !== 0) {
    stueckgewicht =
      (gesamtgewicht_zutaten - gesamtgewicht_zutaten_not_baken) /
      parseFloat(formData.stueckzahl);
  }

  // calculate gebaeckgewicht
  let gebaeckgewicht = 0;
  if (formData.backverlust < 100) {
    gebaeckgewicht = stueckgewicht * ((100 - formData.backverlust) / 100);
  }

  // calculate gesamt gebaeckgewicht
  let gesamt_gebaeckgewicht = 0;
  if (formData.stueckzahl !== 0) {
    gesamt_gebaeckgewicht =
      gebaeckgewicht + gesamtgewicht_zutaten_not_baken / formData.stueckzahl;
  }
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }

  return (
    <div className="view-wrapper rbd add">
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben für das neue Rezept bereits Werte eingetragen. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Helmet title="Rezept Anlegen" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> Rezept Anlegen</h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={true}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={`/controlpanel/rezeptdatenbank/rezepte`}
                  typeString={'das Rezept'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            <div className="rdb add form rezepte">
              <RezeptForm
                isRezeptnummerDuplicate={isRezeptnummerDuplicate}
                formData={formData}
                handleInputChange={handleInputChange}
                bezuege={bezuege}
                zutaten={zutaten}
                addZutat={addZutat}
                removeZutat={removeZutat}
                handleZutatChange={handleZutatChange}
                zutatengruppen={zutatengruppen}
                rohstoffeNames={rohstoffeNames}
                produkteNames={produkteNames}
                grundrezepte={grundrezepte}
                isIncompleteZutat={isIncompleteZutat}
                gesamtgewicht_zutaten={gesamtgewicht_zutaten}
                stueckgewicht={stueckgewicht}
                gebaeckgewicht={gebaeckgewicht}
                gesamt_gebaeckgewicht={gesamt_gebaeckgewicht}
                handleKategorienChange={handleKategorienChange}
                selectedKategorien={formData.kategorien}
                kategorien={rezeptkategorien}
                handleGruppenChange={handleGruppenChange}
                gruppen={rezeptgruppen}
                selectedGruppen={formData.gruppen}
                codeSuggestions={
                  rezepteCheck.subrequests?.[formData_rezeptnummer]?.data
                    .suggested_codes
                }
              />
            </div>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RezeptCreate.propTypes = {
  pathname: PropTypes.string.isRequired,
  getBezuege: PropTypes.func.isRequired,
  bezuege: PropTypes.array.isRequired,
  createRezept: PropTypes.func.isRequired,
  createZutat: PropTypes.func.isRequired,
  getZutatengruppen: PropTypes.func.isRequired,
  zutatengruppen: PropTypes.array.isRequired,
  getRohstoffeNames: PropTypes.func.isRequired,
  rohstoffeNames: PropTypes.array.isRequired,
  getProdukteNames: PropTypes.func.isRequired,
  produkteNames: PropTypes.array.isRequired,
  getGrundrezepte: PropTypes.func.isRequired,
  grundrezepte: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  getRezeptkategorien: PropTypes.func.isRequired,
  rezeptkategorien: PropTypes.array.isRequired,
  getRezeptgruppen: PropTypes.func.isRequired,
  rezeptgruppen: PropTypes.array.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      bezuege: state.bezuege.data.bezuege,
      zutatengruppen: state.zutatengruppen.data.zutatengruppen,
      rohstoffeNames: state.rohstoffeNames.data,
      produkteNames: state.produkteNames.data,
      grundrezepte: state.grundrezepte.data,
      rezeptkategorien: state.rezeptkategorien.data.rezeptkategorien,
      rezeptgruppen: state.rezeptgruppen.data.rezeptgruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      createRezept,
      createZutat,
      getBezuege,
      getZutatengruppen,
      getRohstoffeNames,
      getProdukteNames,
      getGrundrezepte,
      getRezeptkategorien,
      getRezeptgruppen,
      getVistPermissions,
    },
  ),
)(RezeptCreate);
