import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Grid, Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { InlineLoginForm, MixedTitle } from '../.';

import arrowSVG from '../../icons/arrow.svg';

const MoreInfoFooter = ({ name, token }) => {
  let titleLiteral;
  switch (name) {
    case 'produkte':
      titleLiteral = 'Produkten';
      break;
    case 'rezepte':
      titleLiteral = 'Rezepten';
      break;
    case 'koncept':
      titleLiteral = 'Konzepten';
      break;
    default:
      titleLiteral = 'Produkten';
      break;
  }
  return (
    <Container as="section" className="more-info-footer">
      <Grid stackable>
        <Grid.Column width={3}>
          <h2>
            <MixedTitle>Download Sortiments-übersicht</MixedTitle>
          </h2>
          <p>
            Auf paperturn-view.com finden Sie unser Standardsortiment im
            Überblick.
          </p>
          <Button className="medium brandButtonPrimary">
            <a href="https://www.paperturn-view.com/de/innovation-zeelandia-de/sortimentsbersicht-zeelandia?pid=MzY363025&v=3">
              <MixedTitle light="Mehr" strong="Infos" />
              &nbsp;
              <Icon name={arrowSVG} size="23px" className="right" />
            </a>
          </Button>
        </Grid.Column>
        <Grid.Column width={3}>
          <h2>
            <MixedTitle light="Fragen zu" strong={`${titleLiteral}?`} />
          </h2>
          <p>
            {titleLiteral === 'Produkten' &&
              'Haben Sie eine lebensmittelrechtliche Frage oder benötigen weitere Informationen?'}
            {titleLiteral === 'Rezepten' &&
              'Haben Sie eine backtechnische Frage oder benötigen Informationen?'}
            {titleLiteral === 'Konzepten' &&
              'Haben Sie eine weiterführende Frage oder benötigen zusätzliche Informationen?'}
          </p>
          <Button
            className="medium brandButtonPrimary"
            onClick={() => document.getElementById('kontakt')?.scrollIntoView()}
          >
            <MixedTitle light="Zum" strong="Kontakt" />
            &nbsp;
            <Icon name={arrowSVG} size="23px" className="right" />
          </Button>
        </Grid.Column>
        <Grid.Column width={6}>
          {!token && (
            <h2>
              <MixedTitle>Einfach mehr Zeelandia</MixedTitle>
              <div className="title-append">
                Melden Sie Sich an, um unsere exklusiven Services in vollem
                Umfang nutzen zu können.
              </div>
            </h2>
          )}
          <InlineLoginForm />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default connect(
  (state) => ({
    token: state.userSession.token,
  }),
  {},
)(MoreInfoFooter);
