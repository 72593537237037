/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...config.settings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...config.settings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */
import React from 'react';
import { defineMessages } from 'react-intl';
import createBlockStyleButton from 'draft-js-buttons/lib/utils/createBlockStyleButton';
import createBlockBreakoutPlugin from 'draft-js-block-breakout-plugin';
import { Map } from 'immutable';
import { DefaultDraftBlockRenderMap } from 'draft-js';

import { DefaultView } from '@plone/volto/components';

// Circular import problem, so pointing it right to the component
import Icon from '@plone/volto/components/theme/Icon/Icon';

import {
  AlbumView,
  ContactPersonListing,
  ContactPersonsView,
  ContactSalesServiceListing,
  VerkaufskonceptAlbumView,
  Verkaufskonzept,
  ViewHeroBlock,
  ViewHero2Block,
  ViewHero3Block,
  ViewPassendeProdukteBlock,
  ViewPassendeRezepteBlock,
  ViewCountdownBlock,
  EditHeroBlock,
  EditHero2Block,
  EditHero3Block,
  EditPassendeProdukteBlock,
  EditPassendeRezepteBlock,
  EditCountdownBlock,
  SocialMediaView,
  GridAlbumView,
  EditPassendeProdukteBlockNew,
  ViewPassendeProdukteBlockNew,
  EditPassendeRezepteBlockNew,
  ViewPassendeRezepteBlockNew,
} from '@package/components';

import heroBlockSVG from '@package/icons/block1.svg';
import heroBlock2SVG from '@package/icons/block2.svg';
import heroBlock3SVG from '@package/icons/block3.svg';
import passendeProdukteBlockSVG from '@package/icons/block4.svg';
import passendeRezepteBlockSVG from '@package/icons/block5.svg';
import checkmarkslistSVG from '@package/icons/properties.svg';
import arrowlistSVG from '@package/icons/list-arrows.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

// Views

const layoutViews = {
  album_view: AlbumView,
  verkaufskonzept_album_view: VerkaufskonceptAlbumView,
  ihre_ansprechpartner_view: ContactPersonsView,
  ihre_ansprechpartner_listing_view: ContactPersonListing,
  ihre_ansprechpartner_verkaufsservice_listing_view: ContactSalesServiceListing,
  grid_album_view: GridAlbumView,
};

const contentTypesViews = {
  'News Item': DefaultView,
  'jungzeelandia.SalesConcept': Verkaufskonzept,
  Download: SocialMediaView,
};

// Blocks
const blockRenderMap = Map({
  callout: {
    element: 'p',
  },
  unstyled: {
    element: 'p',
  },
  arrowList: {
    element: 'li',
    wrapper: <ul className="arrow-list" />,
  },
  checkmarksList: {
    element: 'li',
    wrapper: <ul className="checkmarks-list" />,
  },
});
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const blockStyleFn = (contentBlock) => {
  const type = contentBlock.getType();
  if (type === 'callout') {
    return 'callout';
  }
  if (type === 'arrowList') {
    return 'arrow-list';
  }
  if (type === 'checkmarksList') {
    return 'checkmarks-list';
  }
  return null;
};

const listBlockTypes = ['arrowList', 'checkmarksList'];

// Inline Toolbar custom buttons
const ArrowListButton = createBlockStyleButton({
  blockType: 'arrowList',
  children: <Icon name={arrowlistSVG} size="24px" />,
});

const CheckMarksListButton = createBlockStyleButton({
  blockType: 'checkmarksList',
  children: <Icon name={checkmarkslistSVG} size="24px" />,
});

// Plugins
const breakOutOptions = {
  doubleBreakoutBlocks: [
    'unordered-list-item',
    'ordered-list-item',
    'code-block',
    'arrowList',
    'checkmarksList',
  ],
  breakoutBlocks: [
    'header-one',
    'header-two',
    'header-three',
    'highlight',
    'blockquote',
    'callout',
  ],
};
const blockBreakoutPlugin = createBlockBreakoutPlugin(breakOutOptions);

// FromHTML
const FromHTMLCustomBlockFn = (element) => {
  if (element.className === 'callout') {
    return {
      type: 'callout',
    };
  }
  if (element.className === 'arrow-list-item') {
    return {
      type: 'arrowList',
    };
  }
  if (element.className === 'checkmarks-list-item') {
    return {
      type: 'checkmarksList',
    };
  }
  return null;
};

// ToHTML
// Returns how the default lists should be rendered
const getList = (ordered) => (children, { depth, keys }) =>
  ordered ? (
    <ol className="tile" key={keys[0]} keys={keys} depth={depth}>
      {children.map((child, i) => (
        <li key={keys[i]}>{child}</li>
      ))}
    </ol>
  ) : (
    <ul className="tile" key={keys[0]} keys={keys} depth={depth}>
      {children.map((child, i) => (
        <li key={keys[i]}>{child}</li>
      ))}
    </ul>
  );

// Special function to deal with list clones
const getSpecialList = (type) => (children, { depth, keys }) => (
  <ul key={keys[0]} keys={keys} depth={depth} className={`tile ${type}`}>
    {children.map((child, i) => (
      <li key={keys[i]} className={`${type}-item`}>
        {child}
      </li>
    ))}
  </ul>
);

const blocks = {
  unstyled: (children, { keys }) =>
    children.map((child) => (
      <p className="tile text" key={keys[0]}>
        {child}
      </p>
    )),
  callout: (children, { keys }) =>
    children.map((child, i) => (
      <p key={keys[i]} className="tile callout">
        {child}
      </p>
    )),
  'unordered-list-item': getList(),
  'ordered-list-item': getList(true),
  arrowList: getSpecialList('arrow-list'),
  checkmarksList: getSpecialList('checkmarks-list'),
};

defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  text: {
    id: 'text',
    defaultMessage: 'Text',
  },
  image: {
    id: 'image',
    defaultMessage: 'Image',
  },
  video: {
    id: 'video',
    defaultMessage: 'Video',
  },
  hero: {
    id: 'hero',
    defaultMessage: 'Hero',
  },
  hero2: {
    id: 'hero2',
    defaultMessage: 'Hero 2',
  },
  hero3: {
    id: 'hero3',
    defaultMessage: 'Hero 3',
  },
  passendeProdukte: {
    id: 'passendeProdukte',
    defaultMessage: 'Passende Produkte',
  },
  passendeRezepte: {
    id: 'passendeRezepte',
    defaultMessage: 'Passende Rezepte',
  },
  countdown: {
    id: 'countdown',
    defaultMessage: 'Countdown',
  },
  label_my_username_is: {
    id: 'label_my_username_is',
    defaultMessage: 'Meine Email Addresse lautet:',
  },
  loginFailedContent: {
    id:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
    defaultMessage:
      'Both email address and password are case sensitive, check that caps lock is not enabled.',
  },
  loginFailed: {
    id: 'Login Failed',
    defaultMessage: 'Login Failed',
  },
});

// Non content routes
const nonContentRoutes = [
  /\/alle-rezepte\/.*$/,
  /\/alle-produkte\/.*$/,
  '/alle-rezepte',
  '/alle-produkte',
  '/mail-register',
  '/mail-error',
  '/mail-success',
];

export default function applyConfig(config) {
  /**
   * Settings
   */
  config.settings.nonContentRoutes.push(...nonContentRoutes);
  config.settings.defaultLanguage = 'de';
  config.settings.supportedLanguages = ['de'];

  // Rich Text settings
  const { richtextEditorSettings } = config.settings;
  config.settings.richtextEditorSettings = (props) => {
    const result = richtextEditorSettings(props);

    result.richTextEditorInlineToolbarButtons.push(
      ...[ArrowListButton, CheckMarksListButton],
    );
    result.richTextEditorPlugins = [
      result.richTextEditorPlugins[0],
      blockBreakoutPlugin,
    ];
    result.extendedBlockRenderMap = extendedBlockRenderMap;
    result.blockStyleFn = blockStyleFn;
    result.FromHTMLCustomBlockFn = FromHTMLCustomBlockFn;
    result.listBlockTypes.push(...listBlockTypes);

    return result;
  };

  config.settings.richtextViewSettings.ToHTMLRenderers.blocks = {
    ...config.settings.richtextViewSettings.ToHTMLRenderers.blocks,
    ...blocks,
  };
  config.settings.richtextViewSettings.ToHTMLRenderers.entities = {
    LINK: (children, entity, { key }) => {
      const url =
        entity.url.substr(entity.url.length - 4).toLowerCase() === '.pdf'
          ? `${config.settings.apiPath}${entity.url}/@@download/file`
          : entity.url;
      return (
        <a key={key} href={url}>
          {children}
        </a>
      );
    },
  };

  config.settings.DEFAULT_PAGING_SIZE = 12;
  config.settings.DEFAULT_NEWS_PAGING_SIZE = 6;
  config.settings.DEFAULT_GRIDALBUM_PAGING_SIZE = 12;
  config.settings.DEFAULT_SEARCHABLE_TYPES = [
    'Document',
    'jungzeelandia.Product',
    'jungzeelandia.Recipe',
    'jungzeelandia.SalesConcept',
    'News Item',
  ];
  config.settings.ONAPPLY_URL =
    'https://zeelandia.onapply.de/feed/render.html?format=json';

  /**
   * Views
   */
  config.views.layoutViews = {
    ...config.views.layoutViews,
    ...layoutViews,
  };
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    ...contentTypesViews,
  };

  /**
   * Blocks
   */

  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'related', title: 'Produkte / Rezepte' },
  ];

  config.blocks.blocksConfig.description.restricted = false;
  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    countdown: {
      id: 'countdown',
      title: 'Countdown',
      icon: passendeRezepteBlockSVG,
      group: 'common',
      view: ViewCountdownBlock,
      edit: EditCountdownBlock,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    hero: {
      id: 'hero',
      title: 'Hero',
      icon: heroBlockSVG,
      group: 'common',
      view: ViewHeroBlock,
      edit: EditHeroBlock,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    hero2: {
      id: 'hero2',
      title: 'Hero 2',
      icon: heroBlock2SVG,
      group: 'common',
      view: ViewHero2Block,
      edit: EditHero2Block,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    hero3: {
      id: 'hero3',
      title: 'Hero 3',
      icon: heroBlock3SVG,
      group: 'common',
      view: ViewHero3Block,
      edit: EditHero3Block,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    passendeProdukteSelect: {
      id: 'passendeProdukteSelect',
      title: 'Passende Produkte',
      icon: passendeProdukteBlockSVG,
      group: 'related',
      view: ViewPassendeProdukteBlockNew,
      edit: EditPassendeProdukteBlockNew,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    passendeRezepteSelect: {
      id: 'passendeRezepteSelect',
      title: 'Passende Rezepte',
      icon: passendeRezepteBlockSVG,
      group: 'related',
      view: ViewPassendeRezepteBlockNew,
      edit: EditPassendeRezepteBlockNew,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    passendeProdukte: {
      id: 'passendeProdukte',
      title: 'Passende Produkte (nach Kategorie)',
      icon: passendeProdukteBlockSVG,
      group: 'related',
      view: ViewPassendeProdukteBlock,
      edit: EditPassendeProdukteBlock,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
    passendeRezepte: {
      id: 'passendeRezepte',
      title: 'Passende Rezepte (nach Kategorie)',
      icon: passendeRezepteBlockSVG,
      group: 'related',
      view: ViewPassendeRezepteBlock,
      edit: EditPassendeRezepteBlock,
      restricted: false,
      mostUsed: false,
      blockHasOwnFocusManagement: true,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
  };

  config.settings.DSGVOBanner = {
    ...config.settings.DSGVOBanner,
    tracker: {
      type: 'matomo',
      id: 2,
      urlBase: 'https://stats.zeelandia.de/',
    },
    modules: ['tracking'],
    privacy_url: '/datenschutz',
  };

  config.settings.pd_path = '/produktdatenblaetter';

  return config;
}
