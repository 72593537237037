import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import {
  updateRezept,
  getRezeptByID,
  deleteRezept,
  createZutat,
  checkRezepte,
  getBezuege,
  getZutatengruppen,
  getRohstoffeNames,
  getProdukteNames,
  getGrundrezepte,
  deleteZutat,
  updateZutat,
  getRezeptkategorien,
  getRezeptgruppen,
  getVistPermissions,
} from '../../../actions';

import { UnsavedChangesWarning, convertNumbersToStrings } from 'helpers';

import ButtonArea from '../ButtonArea';

import RezeptForm from './RezeptForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RezeptEdit = ({
  pathname,
  rezept,
  getRezeptByID,
  updateRezept,
  deleteRezept,
  getBezuege,
  bezuege,
  getZutatengruppen,
  zutatengruppen,
  getRohstoffeNames,
  rohstoffeNames,
  getProdukteNames,
  produkteNames,
  getGrundrezepte,
  grundrezepte,
  createZutat,
  deleteZutat,
  updateZutat,
  getRezeptkategorien,
  rezeptkategorien,
  getRezeptgruppen,
  rezeptgruppen,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    bezeichnung: '',
    rezeptnummer: '',
    rezeptcode: '',
    kennung: '',
    variante: 0,
    intern_extern: '',
    bezuege_id: '',
    brennwert_kj: '',
    brennwert_kcal: '',
    eiweiss: '',
    kohlenhydrate: '',
    zucker: '',
    fett: '',
    ges_fettsaeuren: '',
    ballaststoffe: '',
    natrium: '',
    staerke: '',
    rezeptbeschreibung_lang: '',
    rezeptbeschreibung_kurz: '',
    basis_euro: '',
    zusatzinformationen: '',
    is_import: 0,
    image_uuid: '',
    status: 1,
    aktuell: null,
    kategorien: [],
    gruppen: [],
  });
  const [initialFormData, setInitialFormData] = useState({});
  const [initialZutaten, setInitialZutaten] = useState({});

  const [zutaten, setZutaten] = useState([]);
  const [zutatenToDelete, setZutatenToDelete] = useState([]);

  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isIncompleteZutat, setIsIncompleteZutat] = useState(false);
  const [isRezeptnummerDuplicate, setIsRezeptNummerDuplicate] = useState(false);
  const [backURL, setBackURL] = useState(null);
  const isSubmitDisabled =
    isRezeptnummerDuplicate ||
    formData.name === '' ||
    formData.verkehrsbezeichnung === '' ||
    formData.kennung === '' ||
    formData.rezeptcode === '' ||
    formData.variante === '' ||
    formData.bezuege_id === '' ||
    isIncompleteZutat;

  // dispatch for rezepteCheck()
  const dispatch = useDispatch();
  // rezepteCheck action
  const rezepteCheck = useSelector((state) => state.rezepteCheck);

  // assemble rezeptnummer from formdata
  const formData_rezeptnummer =
    formData.kennung + '-' + formData.rezeptcode + '-' + formData.variante;

  // last edited field
  const [lastField, setLastField] = useState('');

  const [modified, setModified] = useState(false);

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 2, 4, 6];
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    setBackURL(`/controlpanel/rezeptdatenbank/rezepte/details?id=${id}`);
    getRezeptByID(id);
    getBezuege({ getAll: true });
    getZutatengruppen({ getAll: true });
    getRohstoffeNames();
    getProdukteNames();
    getGrundrezepte();
    getRezeptkategorien({ getAll: true });
    getRezeptgruppen({ getAll: true });
    // eslint-disable-next-line
  }, [getRezeptByID, getBezuege]);

  useEffect(() => {
    if (rezept) {
      const rezeptdata = {
        rezeptcode: rezept.rezeptcode,
        variante: rezept.variante,
        bezuege_id: rezept.bezuege_id,
        kopie_von: rezept.kopie_von, //needed?
        mehrzweckfeld: rezept.mehrzweckfeld, //needed?
        name: rezept.name,
        name_etikett: rezept.name_etikett,
        preis: rezept.preis,
        rezeptstatus: rezept.rezeptstatus,
        verkehrsbezeichnung: rezept.verkehrsbezeichnung,
        zusatz: rezept.zusatz,
        intern_extern: rezept.intern_extern,
        aktuell: rezept.aktuell,
        knetzeit: rezept.knetzeit,
        ruehrzeit: rezept.ruehrzeit,
        teigtemperatur: rezept.teigtemperatur,
        massentemperatur: rezept.massentemperatur,
        teigruhe: rezept.teigruhe,
        teigausbeute: rezept.teigausbeute,
        quellzeit: rezept.quellzeit,
        pressen_zwischengare: rezept.pressen_zwischengare,
        backtemperatur: rezept.backtemperatur,
        siedefetttemperatur: rezept.siedefetttemperatur,
        kerntemperatur: rezept.kerntemperatur,
        backzeit: rezept.backzeit,
        herstellungstext: rezept.herstellungstext,
        stueckzahl: rezept.stueckzahl,
        teigeinlage: rezept.teigeinlage,
        pressengewicht: rezept.pressengewicht,
        masseneinwaage: rezept.masseneinwaage,
        backverlust: rezept.backverlust,
        kennung: rezept.kennung,
        etikett: rezept.etikett,
        kategorien: rezept.kategorien,
        gruppen: rezept.gruppen,
      };
      setFormData(rezeptdata);
      setInitialFormData(convertNumbersToStrings(rezeptdata));
      setZutaten(rezept.zutaten);
      setInitialZutaten(
        rezept?.zutaten?.map((zutat) => convertNumbersToStrings(zutat)),
      );
    }
    // eslint-disable-next-line
  }, [rezept]);

  useEffect(() => {
    if (zutaten) {
      if (!zutaten.every((zutat) => zutat.hasOwnProperty('kategorie'))) {
        const updatedZutaten = zutaten.map((zutat) => {
          if (zutat.grundrezepte_id !== 0) {
            zutat.kategorie = 'grundrezept';
          } else if (zutat.rohstoffe_id !== 0) {
            zutat.kategorie = 'rohstoff';
          } else if (zutat.produkte_id !== 0) {
            zutat.kategorie = 'produkt';
          }
          return zutat;
        });

        setZutaten(updatedZutaten);
      }
      setIsIncompleteZutat(
        zutaten.some((zutat) => {
          return (
            (!zutat.rohstoffe_id &&
              !zutat.produkte_id &&
              !zutat.grundrezepte_id) ||
            ((zutat.rohstoffe_id === '0' || zutat.rohstoffe_id === 0) &&
              (zutat.produkte_id === '0' || zutat.produkte_id === 0) &&
              (zutat.grundrezepte_id === '0' || zutat.grundrezepte_id === 0)) ||
            isNaN(parseFloat(zutat.menge)) ||
            zutat.menge === 0 ||
            zutat.menge === '0' ||
            zutat.zutatengruppen_id === 0 ||
            zutat.zutatengruppen_id === '0'
          );
        }),
      );
    }
  }, [zutaten]);

  //check if Form values have changed, to warn user form leaving the page
  useEffect(() => {
    if (!isEqual(convertNumbersToStrings(formData), initialFormData)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [formData]);

  useEffect(() => {
    const normalized_zutaten = zutaten?.map(({ updated, kategorie, ...rest }) =>
      convertNumbersToStrings(rest),
    );
    if (!isEqual(normalized_zutaten, initialZutaten)) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [zutaten]);

  const addZutat = (e) => {
    e.preventDefault();
    setZutaten([
      ...zutaten,
      {
        kategorie: 'rohstoff',
        rezepte_id: '0',
        rohstoffe_id: '0',
        produkte_id: '0',
        zutatengruppen_id: '0',
        grundrezepte_id: '0',
        sorting: zutaten.length,
        info: '',
        menge: '',
        backen: 2, // 1 = true 2= false!!
        select_zut_grundr: 1, // what does this do?
      },
    ]);
  };

  const removeZutat = (e, index) => {
    e.preventDefault();

    if (zutaten[index].id) {
      setZutatenToDelete([zutaten[index].id, ...zutatenToDelete]);
    }

    const updatedZutaten = zutaten.filter((_, i) => i !== index);
    updatedZutaten.forEach((zutat, index) => {
      zutat.sorting = index;
    });
    setZutaten(updatedZutaten);
  };

  const handleZutatChange = (e, index) => {
    const { name, value } = e.target;

    const updatedZutat = { ...zutaten[index] };

    if (e.type === 'click') {
      if (updatedZutat.backen !== 1) {
        updatedZutat.backen = 1;
      } else if (updatedZutat.backen === 1) {
        updatedZutat.backen = 0;
      }
    } else {
      updatedZutat[name] = value;
    }

    if (name === 'kategorie') {
      updatedZutat.rohstoffe_id = '0';
      updatedZutat.produkte_id = '0';
      updatedZutat.grundrezepte_id = '0';
    }

    if (updatedZutat.id) {
      updatedZutat.updated = true;
    }

    const updatedZutatenList = zutaten.filter((_, i) => i !== index);
    updatedZutatenList.splice(updatedZutat.sorting, 0, updatedZutat);
    updatedZutatenList.forEach((zutat, index) => {
      zutat['sorting'] = index;
    });
    setZutaten(updatedZutatenList);
  };

  const handleKategorienChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      kategorien: value,
    }));
  };

  const handleGruppenChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gruppen: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLastField(name);

    if (name === 'intern_extern') {
      // For intern/extern field, convert value to a boolean
      const isIntern = value === 'intern';
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: isIntern,
      }));
    } else if (name === 'kennung') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.toUpperCase(),
      }));
    } else if (name === 'rezeptcode') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.replace(/\D/g, ''),
      }));
    } else if (name === 'variante') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: Math.min(parseInt(value), 99),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    let kennung = name === 'kennung' ? value : formData.kennung;
    let rezeptcode = name === 'rezeptcode' ? value : formData.rezeptcode;
    let variante = name === 'variante' ? value : formData.variante || 0;

    let rezeptnummer = `${kennung}-${rezeptcode}-${variante}`;

    dispatch(checkRezepte('rezeptnummer', rezeptnummer, rezeptnummer));
    setIsRezeptNummerDuplicate(
      rezepteCheck.subrequests?.[rezeptnummer]?.data.value_exists,
    );
    // }
  };

  // set variante to lowest available variante if rezeptcode is already in use
  // set isRezeptNummerDuplicate to true if the user changes to an already existing variant
  useEffect(() => {
    // only do this if there is data for the suggestions
    if (
      rezepteCheck.subrequests[formData_rezeptnummer]?.data?.suggested_variants
        ?.length > 0
    ) {
      // extract lowest possible variant
      let new_variante =
        rezepteCheck.subrequests[formData_rezeptnummer].data
          .suggested_variants[0];

      // extract if the current rezeptnummer is a duplicate
      let isDuplicate =
        rezepteCheck.subrequests[formData_rezeptnummer].data.value_exists;

      if (
        formData_rezeptnummer ===
        `${rezept.kennung}-${rezept.rezeptcode}-${rezept.variante}`
      ) {
        setIsRezeptNummerDuplicate(false);
      } else if (new_variante !== undefined && lastField === 'rezeptcode') {
        // set variant to lowest possible
        setFormData((prevFormData) => ({
          ...prevFormData,
          variante: new_variante,
        }));
        // set duplicate flag to false since the new one is not used
        setIsRezeptNummerDuplicate(false);
      } else if (isDuplicate !== undefined && lastField === 'variante') {
        // if user changes variante set duplicate flag to value from endpoint
        setIsRezeptNummerDuplicate(isDuplicate);
      }

      if (
        lastField === 'rezeptcode' &&
        formData.rezeptcode === rezept.rezeptcode
      ) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          variante: rezept.variante,
        }));
        setIsRezeptNummerDuplicate(false);
      }
    }
    // eslint-disable-next-line
  }, [rezepteCheck.subrequests]);

  const handleSubmit = async (e) => {
    setModified(false);
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      const result = await updateRezept(id, formData);
      const updatedId = result.id;

      if (updatedId) {
        await Promise.all(
          zutaten.map(async (zutat) => {
            if (zutat.id && zutat.updated) {
              try {
                await updateZutat(zutat.id, zutat);
              } catch (error) {
                setError(
                  error.name +
                    ': Es ist etwas schiefgelaufen, Zutat wurde nicht bearbeitet',
                );
                setErrorDetail(error.name + ': ' + error.message);
              }
            } else if (!zutat.id && !zutat.updated) {
              zutat.rezepte_id = updatedId;
              createZutat(zutat).catch((error) => {
                setError(
                  error.name +
                    ': Es ist etwas schiefgelaufen, Zutat wurde nicht angelegt',
                );
                setErrorDetail(error.name + ': ' + error.message);
              });
            }
          }),
        );
        await Promise.all(
          zutatenToDelete.map(async (zutat_id) => {
            try {
              await deleteZutat(zutat_id);
            } catch (error) {
              setError(
                error.name +
                  ': Es ist etwas schiefgelaufen, Zutat konnte nicht gelöscht werden',
              );
              setErrorDetail(error.name + ': ' + error.message);
            }
          }),
        );
        history.push(
          `/controlpanel/rezeptdatenbank/rezepte/details?id=${updatedId}`,
        );
      } else {
        setError(
          error.name +
            ': Es ist etwas schiefgelaufen, Rezept wurde nicht angelegt.',
        );
      }
    } catch (error) {
      setError(
        error.name +
          ': Es ist etwas schiefgelaufen, Rezept wurde nicht bearbeitet',
      );
      setErrorDetail(error.name + ': ' + error.message);
    }
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteRezept(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error.name);
    }
  };

  // Calculations:

  // Calculate gesamtgewicht_zutaten
  const gesamtgewicht_zutaten = zutaten?.reduce(
    (totalWeight, zutat) => totalWeight + parseFloat(zutat.menge || 0),
    0,
  );

  // Calculate gesamtgewicht_zutaten_not_baken
  const gesamtgewicht_zutaten_not_baken = zutaten
    ?.filter((zutat) => zutat.backen !== 1)
    ?.reduce(
      (totalWeight, zutat) => totalWeight + parseFloat(zutat.menge || 0),
      0,
    );

  // calculate stueckgewicht
  let stueckgewicht = 0;
  if (formData.stueckzahl !== 0) {
    stueckgewicht =
      (gesamtgewicht_zutaten - gesamtgewicht_zutaten_not_baken) /
      parseFloat(formData.stueckzahl);
  }

  // calculate gebaeckgewicht
  let gebaeckgewicht = 0;
  if (formData.backverlust < 100) {
    gebaeckgewicht = stueckgewicht * ((100 - formData.backverlust) / 100);
  }

  // calculate gesamt gebaeckgewicht
  let gesamt_gebaeckgewicht = 0;
  if (formData.stueckzahl !== 0) {
    gesamt_gebaeckgewicht =
      gebaeckgewicht + gesamtgewicht_zutaten_not_baken / formData.stueckzahl;
  }
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }

  return (
    <div className="view-wrapper">
      <Helmet title="Rezept Bearbeiten" />
      <UnsavedChangesWarning
        condition={modified}
        message="Sie haben das Rezept bearbeitet. Wenn Sie diese Seite verlassen gehen alle Änderungen verloren. Wollen sie die Seite wirklich verlassen?"
      />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {rezept && rezept.name} </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={backURL}
                  showDeleteButton={showDeleteButton}
                  typeString={'das Rezept'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Rezept wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/rezepte"
                >
                  Zurück zur Rezepte-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                <div className="rdb edit form rezepte">
                  <RezeptForm
                    formData={formData}
                    isRezeptnummerDuplicate={isRezeptnummerDuplicate}
                    handleInputChange={handleInputChange}
                    bezuege={bezuege}
                    zutaten={zutaten}
                    rohstoffeNames={rohstoffeNames}
                    produkteNames={produkteNames}
                    grundrezepte={grundrezepte}
                    zutatengruppen={zutatengruppen}
                    addZutat={addZutat}
                    isIncompleteZutat={isIncompleteZutat}
                    handleZutatChange={handleZutatChange}
                    removeZutat={removeZutat}
                    gesamtgewicht_zutaten={gesamtgewicht_zutaten}
                    stueckgewicht={stueckgewicht}
                    gebaeckgewicht={gebaeckgewicht}
                    gesamt_gebaeckgewicht={gesamt_gebaeckgewicht}
                    handleKategorienChange={handleKategorienChange}
                    selectedKategorien={formData.kategorien}
                    kategorien={rezeptkategorien}
                    handleGruppenChange={handleGruppenChange}
                    selectedGruppen={formData.gruppen}
                    gruppen={rezeptgruppen}
                    codeSuggestions={
                      rezepteCheck.subrequests?.[formData_rezeptnummer]?.data
                        .suggested_codes
                    }
                  />
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

RezeptEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  rezept: PropTypes.object,
  bezuege: PropTypes.array.isRequired,
  getBezuege: PropTypes.func.isRequired,
  getRezeptByID: PropTypes.func.isRequired,
  updateRezept: PropTypes.func.isRequired,
  deleteRezept: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  getZutatengruppen: PropTypes.func.isRequired,
  zutatengruppen: PropTypes.array.isRequired,
  getRohstoffeNames: PropTypes.func.isRequired,
  rohstoffeNames: PropTypes.array.isRequired,
  getProdukteNames: PropTypes.func.isRequired,
  produkteNames: PropTypes.array.isRequired,
  getGrundrezepte: PropTypes.func.isRequired,
  grundrezepte: PropTypes.array.isRequired,
  createZutat: PropTypes.func.isRequired,
  updateZutat: PropTypes.func.isRequired,
  deleteZutat: PropTypes.func.isRequired,
  getRezeptkategorien: PropTypes.func.isRequired,
  rezeptkategorien: PropTypes.array.isRequired,
  getRezeptgruppen: PropTypes.func.isRequired,
  rezeptgruppen: PropTypes.array.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      rezept: state.rezeptByID.data,
      bezuege: state.bezuege.data.bezuege,
      zutatengruppen: state.zutatengruppen.data.zutatengruppen,
      rohstoffeNames: state.rohstoffeNames.data,
      produkteNames: state.produkteNames.data,
      grundrezepte: state.grundrezepte.data,
      rezeptkategorien: state.rezeptkategorien.data.rezeptkategorien,
      rezeptgruppen: state.rezeptgruppen.data.rezeptgruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      updateRezept,
      getRezeptByID,
      deleteRezept,
      createZutat,
      getBezuege,
      getZutatengruppen,
      getRohstoffeNames,
      getProdukteNames,
      getGrundrezepte,
      deleteZutat,
      updateZutat,
      getRezeptkategorien,
      getRezeptgruppen,
      getVistPermissions,
    },
  ),
)(RezeptEdit);
