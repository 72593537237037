import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Form, Dropdown } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  createBenutzer,
  checkBenutzer,
  getGruppen,
  getVistPermissions,
  getPloneVistUsers,
} from '../../../actions';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const BenutzerCreate = ({ intl }) => {
  const [formData, setFormData] = useState({
    loginname: '',
    vorname: '',
    nachname: '',
    userlevel: '',
    gruppen: [],
    plone_user: '',
  });
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [isLoginnameDuplicate, setIsLoginnameDuplicate] = useState(false);
  const [selectedGruppen, setSelectedGruppen] = useState([]);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      dispatch(getVistPermissions())
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getGruppen({ getAll: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPloneVistUsers());
  }, []);

  const history = useHistory();

  const pathname = useSelector((state) => state.router.location.pathname);
  const benutzer = useSelector((state) => state.benutzerByID.data);
  const gruppen = useSelector((state) => state.gruppen.data.gruppen);
  const token = useSelector((state) => state.userSession.token);
  const permissions = useSelector((state) => state.permissions.data);
  const vist_users = useSelector((state) => state.ploneVistUsers);

  const gruppenOptions = gruppen.map((gruppe) => ({
    key: gruppe.id,
    text: gruppe.name,
    value: gruppe.id,
  }));

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  // };

  const handleLoginnameChange = (e) => {
    const { value } = e.target;

    dispatch(checkBenutzer('loginname', value))
      .then((result) => {
        if (result === true) {
          setIsLoginnameDuplicate(true);
        } else {
          setIsLoginnameDuplicate(false);
        }
      })
      .catch((error) => {
        setError(error.name);
        setErrorDetail(error.message);
      });

    setFormData((prevFormData) => ({ ...prevFormData, loginname: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const cleanedFormData = Object.entries(formData).reduce(
      (acc, [key, value]) => {
        if (value !== '') {
          acc[key] = value;
        }
        return acc;
      },
      {},
    );

    dispatch(createBenutzer(cleanedFormData))
      .then((result) => {
        const id = result.id;
        if (id) {
          history.push(
            `/controlpanel/rezeptdatenbank/benutzer/details?id=${id}`,
          );
        } else {
          setError(
            'Es ist etwas schiefgelaufen, Benutzer wurde nicht angelegt.',
          );
        }
      })
      .catch((error) => {
        setError('Es ist etwas schiefgelaufen, Rohstoff wurde nicht angelegt');
        setErrorDetail(error.name + ': ' + error.message);
      });
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }

  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }

  return (
    <div className="view-wrapper rbd add">
      <Helmet title="Benutzer Anlegen" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> Benutzer Anlegen</h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  isAdd={true}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={
                    isLoginnameDuplicate || formData.loginname === ''
                  }
                  backURL={'/controlpanel/rezeptdatenbank/benutzer'}
                  typeString={'den Benutzer'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            <div className="rdb add form benutzer">
              <Form onSubmit={handleSubmit}>
                <div className="meta-info">
                  <Form.Field error={isLoginnameDuplicate}>
                    <label>
                      <strong>Benutzername (*)</strong>
                    </label>
                    <input
                      type="text"
                      name="loginname"
                      value={formData.loginname}
                      onChange={handleLoginnameChange}
                      required
                    />
                  </Form.Field>
                  {isLoginnameDuplicate && (
                    <div className="speech-bubble warning">
                      Loginname ist bereits vergeben
                    </div>
                  )}
                  <Form.Field>
                    <label>
                      <strong>Plone Benutzer (*):</strong>
                    </label>
                    <Dropdown
                      placeholder="Plone Nutzer Auswahl"
                      fluid
                      selection
                      clearable
                      loading={vist_users.loading}
                      options={[
                        ...vist_users.data.map((user) => {
                          return { key: user, text: user, value: user };
                        }),
                        {
                          key: '0',
                          text: 'Kein Plone Nutzer',
                          value: 'none',
                        },
                      ]}
                      value={formData.plone_user}
                      onChange={(e, { value }) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          plone_user: value,
                        }));
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Gruppen:</label>
                    <Dropdown
                      placeholder="Select Gruppen"
                      fluid
                      multiple
                      selection
                      options={gruppenOptions}
                      value={selectedGruppen}
                      onChange={(e, { value }) => {
                        setSelectedGruppen(value);
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          gruppen: value,
                        }));
                      }}
                    />
                  </Form.Field>
                  <p className="disclaimer">* Pflichtfeld</p>
                </div>
              </Form>
            </div>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

BenutzerCreate.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(BenutzerCreate);
