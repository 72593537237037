import React from 'react';

import { Button, Form, Checkbox } from 'semantic-ui-react';

const ZutatenForm = ({
  zutaten,
  addZutat,
  handleZutatChange,
  removeZutat,
  zutatengruppen,
  rohstoffeNames,
  produkteNames,
  grundrezepte,
  isIncompleteZutat,
}) => {
  const handleSortUp = (index) => {
    if (index === 0) return; // The first zutat can only be moved down
    const newZutaten = [...zutaten];
    newZutaten[index].sorting -= 1;
    newZutaten[index - 1].sorting += 1;
    handleZutatChange(
      { target: { name: 'sorting', value: newZutaten[index].sorting } },
      index,
    );
    handleZutatChange(
      { target: { name: 'sorting', value: newZutaten[index - 1].sorting } },
      index - 1,
    );
  };

  const handleSortDown = (index) => {
    if (index === zutaten.length - 1) return; // The last zutat can only be moved up
    const newZutaten = [...zutaten];
    newZutaten[index].sorting += 1;
    newZutaten[index + 1].sorting -= 1;
    handleZutatChange(
      { target: { name: 'sorting', value: newZutaten[index].sorting } },
      index,
    );
    handleZutatChange(
      { target: { name: 'sorting', value: newZutaten[index + 1].sorting } },
      index + 1,
    );
  };

  const handleKeyDown = (e) => {
    // Prevent form submission on "Enter" key press so "delete button" does not get triggered accidentially
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className="zutatenForm">
      <div className="row">
        <p>Kategorie</p>
        <p>Zutat</p>
        <p>Menge in g</p>
        <p>Info</p>
        <p>Zutatengruppe</p>
        <p>Backen</p>
        <p>Reihenfolge</p>
        <p>Löschen</p>
      </div>
      {zutaten &&
        zutaten.map((zutat, index) => (
          <React.Fragment key={index}>
            <Form className="row" key={index} onKeyDown={handleKeyDown}>
              <Form.Field>
                <select
                  type="select"
                  name="kategorie"
                  onChange={(e) => handleZutatChange(e, index)}
                  value={zutat.kategorie}
                >
                  <option value="rohstoff">Rohstoff</option>
                  <option value="produkt">Produkt</option>
                  <option value="grundrezept">Grundrezept</option>
                </select>
              </Form.Field>
              {zutat.kategorie === 'rohstoff' && (
                <Form.Field error={zutat.rohstoffe_id === '0'}>
                  <select
                    type="select"
                    name="rohstoffe_id"
                    onChange={(e) => handleZutatChange(e, index)}
                    value={zutat.rohstoffe_id}
                  >
                    <option value="0">Rohstoff</option>
                    {rohstoffeNames &&
                      rohstoffeNames.map((rohstoff) => (
                        <option value={rohstoff.id}>
                          {rohstoff.bezeichnung}
                        </option>
                      ))}
                  </select>
                </Form.Field>
              )}
              {zutat.kategorie === 'produkt' && (
                <Form.Field error={zutat.produkte_id === '0'}>
                  <select
                    type="select"
                    name="produkte_id"
                    onChange={(e) => handleZutatChange(e, index)}
                    value={zutat.produkte_id}
                  >
                    <option value="0">Produkt</option>
                    {produkteNames &&
                      produkteNames.map((produkt) => (
                        <option value={produkt.id}>
                          {produkt.bezeichnung}
                        </option>
                      ))}
                  </select>
                </Form.Field>
              )}
              {zutat.kategorie === 'grundrezept' && (
                <Form.Field error={zutat.grundrezepte_id === '0'}>
                  {console.log(zutat.grundrezepte_id)}
                  <select
                    type="select"
                    name="grundrezepte_id"
                    onChange={(e) => handleZutatChange(e, index)}
                    value={zutat.grundrezepte_id}
                  >
                    <option value="0">Grundrezept</option>
                    {grundrezepte &&
                      grundrezepte.map((rezept) => (
                        <option key={rezept.id} value={rezept.id}>
                          {rezept.name}
                        </option>
                      ))}
                  </select>
                </Form.Field>
              )}
              {!zutat.kategorie && (
                <Form.Field error={zutat.rohstoffe_id === '0'}>
                  <select
                    type="select"
                    name="rohstoffe_id"
                    value={zutat.rohstoffe_id}
                    onChange={(e) => handleZutatChange(e, index)}
                  >
                    {rohstoffeNames &&
                      rohstoffeNames.map((rohstoff) => (
                        <option value={rohstoff.id}>
                          {rohstoff.bezeichnung}
                        </option>
                      ))}
                  </select>
                </Form.Field>
              )}

              <Form.Field
                error={
                  zutat.menge === (0 || '' || '0' || 999999) ///999999 is max number as only 6 digit numbers should be allowed
                }
              >
                <input
                  type="number"
                  name="menge"
                  min={0}
                  max={999999}
                  value={zutat.menge}
                  onChange={(e) => handleZutatChange(e, index)}
                />
              </Form.Field>
              <Form.Field>
                <input
                  type="text"
                  name="info"
                  value={zutat.info}
                  onChange={(e) => handleZutatChange(e, index)}
                />
              </Form.Field>
              <Form.Field error={zutat.zutatengruppen_id === '0'}>
                <select
                  type="select"
                  name="zutatengruppen_id"
                  value={zutat.zutatengruppen_id}
                  onChange={(e) => handleZutatChange(e, index)}
                >
                  <option value={0}>Zutatengruppe</option>
                  {zutatengruppen &&
                    zutatengruppen.map((zutatengruppe) => (
                      <option value={zutatengruppe.id}>
                        {zutatengruppe.name}
                      </option>
                    ))}
                </select>
              </Form.Field>
              <Form.Field>
                <Checkbox
                  name="backen"
                  label="backen"
                  checked={zutat.backen === 1}
                  onChange={(e) => handleZutatChange(e, index)}
                />
              </Form.Field>
              <Form.Field>
                {index === 0 ? (
                  <Button disabled icon="arrow down" type="button">
                    ↑
                  </Button>
                ) : (
                  <Button
                    icon="arrow up"
                    onClick={() => handleSortUp(index)}
                    type="button"
                  >
                    ↑
                  </Button>
                )}
                {index === zutaten.length - 1 ? (
                  <Button disabled icon="arrow up" type="button">
                    ↓
                  </Button>
                ) : (
                  <Button
                    icon="arrow down"
                    onClick={() => handleSortDown(index)}
                    type="button"
                  >
                    ↓
                  </Button>
                )}
              </Form.Field>
              <Form.Field>
                <Button onClick={(e) => removeZutat(e, index)}>Löschen</Button>
              </Form.Field>
            </Form>
          </React.Fragment>
        ))}

      <div className="row add">
        <Button onClick={addZutat} disabled={isIncompleteZutat}>
          Zutat Hinzufügen
        </Button>
      </div>
    </div>
  );
};

export default ZutatenForm;
