import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Form, Input, Button } from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';

import { searchContentAdvanced } from '../../actions';

import searchSVG from '../../static/search.svg';

import config from '@plone/volto/registry';

/**
 *
 *
 * @class InlineSearch
 * @extends {Component}
 */
class InlineSearch extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContentAdvanced: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    // searchableText: PropTypes.string,
    // subject: PropTypes.string,
    // path: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    ).isRequired,
    // pathname: PropTypes.string.isRequired,
  };

  state = {
    text: '',
  };

  searchInputRef = createRef();

  componentDidMount() {
    this.searchInputRef.current.focus();
  }

  /**
   * On change text
   * @method onChangeText
   * @param {object} event Event object.
   * @param {string} value Text value.
   * @returns {undefined}
   */
  onChangeText = (event, { value }) => {
    this.setState({
      text: value,
    });
    if (this.promise) {
      this.promise.request.abort();
      this.promise = undefined;
    }

    this.promise = this.props.searchContentAdvanced('', {
      SearchableText: value,
      use_solr: true,
      portal_type: config.settings.DEFAULT_SEARCHABLE_TYPES,
    });
  };

  /**
   * Submit handler
   * @method onSubmit
   * @param {event} event Event object.
   * @returns {undefined}
   */
  onSubmit = (event) => {
    this.props.closeMenu(event);
    this.props.history.push(`/search?SearchableText=${this.state.text}`);
    event.preventDefault();
  };

  getItemURL = (item) => {
    let type;
    if (item.portal_type) {
      type = item.portal_type;
    } else {
      type = item['@type'];
    }
    switch (type) {
      case 'jungzeelandia.Recipe':
        return `/alle-rezepte/${item.id}`;
      case 'jungzeelandia.Product':
        return `/alle-produkte/${item.id}`;
      case 'jungzeelandia.SalesConcept':
      case 'Document':
      case 'Image':
      case 'News Item':
        return item['@id'];
      default:
        break;
    }
    return '';
  };

  getItemPrefix = (item) => {
    switch (item.portal_type) {
      case 'jungzeelandia.Recipe':
        return 'Rezept: ';
      case 'jungzeelandia.Product':
        return 'Produkt: ';
      case 'Jungzeelandia.SalesConcept':
        return 'Verkaufskonzept: ';
      default:
        return '';
    }
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <Form action="/search" onSubmit={this.onSubmit} className="inline-search">
        <h1>Suche nach:</h1>
        <Form.Field className="searchbox" style={{ margin: 0 }}>
          <Input
            onChange={this.onChangeText}
            name="SearchableText"
            value={this.state.text}
            transparent
            tabIndex={0}
            placeholder="Suchbegriff hier eingeben"
            title="Suche"
            autoComplete="off"
            ref={this.searchInputRef}
          />
          <Button>
            <img src={searchSVG} alt="search" />
          </Button>
        </Form.Field>
        <CSSTransition
          in={this.state.text && this.props.items.length}
          timeout={500}
          classNames="autocomplete-block"
          unmountOnExit
        >
          <div className="autocomplete-block">
            <ul>
              {this.props.items.map((item) => (
                <li key={item['@id']}>
                  <Link
                    to={this.getItemURL(item)}
                    onClick={(event) => this.props.closeMenu(event)}
                  >
                    {this.getItemPrefix(item)}
                    {item.title || item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </CSSTransition>
      </Form>
    );
  }
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      items: state.search.items,
      // loaded: state.search.loaded,
      // searchableText: props.location.query.SearchableText,
      // subject: props.location.query.Subject,
      // path: props.location.query.path,
      // pathname: props.location.pathname,
    }),
    { searchContentAdvanced },
  ),
)(InlineSearch);
