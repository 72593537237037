import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Grid, Item } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import { getRecipes } from '../../../actions';
import { MixedTitle } from '../../.';

import { chunk, map } from 'lodash';

import missingIMG from '../../../static/missing.jpg';
import arrowSVG from '../../../icons/arrow.svg';

const View = (props) => {
  const { id, data, isEditMode } = props;
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(Boolean(isEditMode));
  const items = showAll ? data.recipes : props.data.recipes?.slice(0, 8);
  const recipes = useSelector(
    (state) => state.recipes?.subrequests?.[id]?.data,
  );

  useEffect(() => {
    dispatch(getRecipes(items, id));
  }, [showAll, data.recipes]);

  let itemPairs = chunk(recipes, 2);

  return (
    <div className="block tile passende-rezepte">
      <div className="passende-rezepte-listing">
        <h2>Passende Rezepte</h2>
        <Grid columns={2} stackable={true}>
          {map(itemPairs, (itemPair) => (
            <Grid.Row>
              {map(itemPair, (item) => (
                <Grid.Column>
                  <Item.Group unstackable={true}>
                    <Item>
                      <Item.Image
                        src={
                          (item.image &&
                            `${item.image['@id']}/@@images/image/mini`) ||
                          missingIMG
                        }
                        alt={item.name}
                      />
                      <Item.Content>
                        <Item.Header>
                          {isEditMode ? (
                            <>
                              <b>{item.display_rezeptcode}</b> {item.name}
                            </>
                          ) : (
                            <Link to={`/alle-rezepte/${item.id}`}>
                              <b>{item.display_rezeptcode}</b> {item.name}
                            </Link>
                          )}
                        </Item.Header>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </Grid.Column>
              ))}
            </Grid.Row>
          ))}
        </Grid>
        {data.recipes?.length > 8 && showAll === false && (
          <Grid>
            <Grid.Row>
              <Grid.Column stretched={true}>
                <Card.Group stackable centered itemsPerRow={2}>
                  <Card
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '155px',
                    }}
                  >
                    <Button
                      className="small brandButtonSecondary"
                      onClick={() => setShowAll(!showAll)}
                    >
                      <MixedTitle reverse>alle Rezepte anzeigen</MixedTitle>
                      <Icon name={arrowSVG} size="17px" className="right" />
                    </Button>
                  </Card>
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default View;
