import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Confirm, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateGebinde,
  getGebindeByID,
  deleteGebinde,
  getVistPermissions,
} from '../../../actions';
import GebindeForm from './GebindeForm';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const GebindeEdit = ({
  pathname,
  gebinde,
  updateGebinde,
  getGebindeByID,
  deleteGebinde,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const [formData, setFormData] = useState({
    produkt_id: '',
    artikelnummer: '',
    name: '',
    zugabe: '',
  });

  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [backURL, setBackURL] = useState(null);

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 4, 6];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    getGebindeByID(id);
  }, [getGebindeByID]);

  useEffect(() => {
    if (gebinde) {
      setFormData({
        produkt_id: gebinde.produkt_id,
        artikelnummer: gebinde.artikelnummer,
        name: gebinde.name,
        zugabe: gebinde.zugabe,
      });
      setBackURL(
        `/controlpanel/rezeptdatenbank/produkte/details?id=${gebinde.produkt_id}`,
      );
    }
  }, [gebinde]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'artikelnummer') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value.replace(/\D/g, ''),
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await updateGebinde(id, formData);
      history.push(
        `/controlpanel/rezeptdatenbank/produkte/details?id=${formData.produkt_id}`,
      );
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error.name);
    }
  };

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmOpen(false);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteGebinde(id);
      history.push(
        `/controlpanel/rezeptdatenbank/produkte/details?id=${gebinde.produkt_id}`,
      );
      // Handle success or show a success message
    } catch (error) {
      // Handle delete error
      setError(error.name);
    }
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };
  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper rbd add">
      <Helmet title="Gebinde Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>
                  {gebinde && gebinde.artikelnummer + ' - ' + gebinde.name}{' '}
                  Bearbeiten
                </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  isSubmitDisabled={
                    formData.artikelnummer === '' || formData.name === ''
                  }
                  backURL={backURL}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error}</p>
              </Message>
            )}
            <>
              <div className="rdb edit form gebinde">
                <GebindeForm
                  formData={formData}
                  handleInputChange={handleInputChange}
                />
                <Confirm
                  open={confirmOpen}
                  onCancel={handleCancelDelete}
                  onConfirm={handleConfirmDelete}
                  header="Gebinde Löschen"
                  content="Möchten Sie das Gebinde wirklich Löschen?"
                  cancelButton="Abbrechen"
                  confirmButton="Löschen"
                />
              </div>
            </>
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

GebindeEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  gebinde: PropTypes.object,
  getGebindeByID: PropTypes.func.isRequired,
  updateGebinde: PropTypes.func.isRequired,
  deleteGebinde: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      gebinde: state.gebindeByID.data,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    { updateGebinde, getGebindeByID, deleteGebinde, getVistPermissions },
  ),
)(GebindeEdit);
