import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateGruppe,
  getGruppeByID,
  getGruppen,
  deleteGruppe,
  getVistPermissions,
} from '../../../actions';

import ButtonArea from '../ButtonArea';

import GruppeForm from './GruppeForm';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const GruppeEdit = ({
  pathname,
  gruppe,
  gruppen,
  getGruppen,
  getGruppeByID,
  updateGruppe,
  deleteGruppe,
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isGruppeNameDuplicate, setIsGruppeNameDuplicate] = useState(false);
  const isSubmitDisabled = formData.name === '';

  const history = useHistory();
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    getGruppen({ showAll: true });
    getGruppeByID(id);
  }, [getGruppeByID, getGruppen]);

  useEffect(() => {
    if (gruppe) {
      setFormData({
        name: gruppe.name,
      });
    }
  }, [gruppe]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'name' && value !== gruppe.name) {
      const isDuplicate = gruppen.some((gruppe) => gruppe.name === value);
      setIsGruppeNameDuplicate(isDuplicate);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await updateGruppe(id, formData);
      history.push(`/controlpanel/rezeptdatenbank/benutzergruppen`);
      // Handle success or show a success message
    } catch (error) {
      // Handle error during update
      setError(error);
    }
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteGruppe(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  return (
    <div className="view-wrapper">
      <Helmet title="Gruppe Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1>{gruppe && gruppe.name} </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleSubmit={handleSubmit}
                  handleDelete={handleDelete}
                  isSubmitDisabled={isSubmitDisabled}
                  backURL={'/controlpanel/rezeptdatenbank/benutzergruppen'}
                  typeString={'die Gruppe'}
                />
              </div>
            </div>
          </Segment>
          <Segment className="rdbAddEditForm">
            {error && (
              <Message negative>
                <Message.Header>Error</Message.Header>
                <p>{error.message}</p>
              </Message>
            )}
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Gruppe wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/benutzergruppen"
                >
                  Zurück zur Gruppen-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                <div className="rdb edit form gruppen">
                  <GruppeForm
                    formData={formData}
                    isGruppeNameDuplicate={isGruppeNameDuplicate}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

GruppeEdit.propTypes = {
  pathname: PropTypes.string.isRequired,
  gruppen: PropTypes.array.isRequired,
  gruppe: PropTypes.object,
  getGruppeByID: PropTypes.func.isRequired,
  updateGruppe: PropTypes.func.isRequired,
  deleteGruppe: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      gruppe: state.gruppeByID.data,
      gruppen: state.gruppen.data.gruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    {
      updateGruppe,
      getGruppeByID,
      deleteGruppe,
      getGruppen,
      getVistPermissions,
    },
  ),
)(GruppeEdit);
