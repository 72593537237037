import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Loader, Dimmer } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getRezepte,
  updateRezept,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
  getRezeptgruppen,
  getRezeptkategorien,
} from '../../../actions';

import Filters from '../Filters';
import { BasicTable } from '../../ReactTableContent/ReactTableContent';

import { createColumnHelper } from '@tanstack/react-table';
import warningTriangle from '../icons/unvollstaendige_naehrwerte.gif';

import NavHeader from '../NavHeader';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const RezepteListing = ({
  pathname,
  rezepte,
  totalPages,
  getRezepte,
  updateRezept,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  setBatchingParameters,
  resetBatchingParameters,
  batchingParameters,
}) => {
  const currentListing = 'rezepte';
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 12, 13, 14, 15, 2, 4, 6];
  // Define the allowed user groups for adding new Rohstoffe
  const showAddButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // Define the allowed user groups for toggle Listing to show deleted
  const showShowDeletedButton = [1].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // table config
  const [tableConfig, setTableConfig] = useState({
    COLUMNS: [],
    showDeleted: false,
    data: [],
    totalPages: 0,
  });

  const deletedButton = !tableConfig.showDeleted
    ? 'Gelöschte Rezepte anzeigen'
    : 'Rezepte anzeigen';

  const [refreshFlag, setRefreshFlag] = useState(false);

  const columnHelper = createColumnHelper();

  const COLUMNS = [
    columnHelper.accessor('rezeptnummer', {
      id: 'rezeptnummer',
      header: () => <span>Rezeptnummer</span>,
      cell: (info) => (
        <p>
          {info.row.original.kennung.toUpperCase()}{' '}
          {info.row.original.rezeptcode} {info.row.original.variante}
        </p>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>Rezeptname</span>,
      cell: (info) => (
        <p>
          {!info.row.original.verkaufsinformation_id && (
            <img
              style={{ height: '10px', marginRight: '2px' }}
              src={warningTriangle}
              title="Keine Verkaufsinformation"
            />
          )}
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('verkehrsbezeichnung', {
      id: 'verkehrsbezeichnung',
      header: () => <span>Verkehrsbezeichnung</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('rezeptstatus', {
      id: 'rezeptstatus',
      header: () => <span>Status</span>,
      cell: (info) => {
        if (info.getValue() === 1) {
          return (
            <div className="status">
              <div className="green" /> <p>Aktuell</p>
            </div>
          );
        } else if (info.getValue() === 2) {
          return (
            <div className="status">
              <div className="red" />
              <p>Stillgelegt</p>
            </div>
          );
        } else if (info.getValue() === 3) {
          return (
            <div className="status">
              <div className="yellow" />
              <p>In Bearbeitung</p>
            </div>
          );
        } else {
          return <p>{info.getValue()}</p>;
        }
      },
    }),
    columnHelper.accessor('intern_extern', {
      id: 'intern_extern',
      header: () => <span>Intern Extern</span>,
      cell: (info) => <p>{info.getValue() ? 'Intern' : 'Extern'}</p>,
    }),

    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        if (timestamp > 0) {
          return <p>{formattedDate}</p>;
        } else {
          return <p></p>;
        }
      },
    }),
    columnHelper.accessor('benutzer', {
      id: 'benutzer',
      header: () => <span>Ersteller</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
  ];

  const COLUMNS_DELETED = [
    columnHelper.accessor('kennung', {
      id: 'kennung',
      header: () => <span>Rezeptnummer</span>,
      cell: (info) => (
        <p>
          {info.row.original.kennung.toUpperCase()}{' '}
          {info.row.original.rezeptcode} {info.row.original.variante}
        </p>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>Rezeptname</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),
    columnHelper.accessor('verkehrsbezeichnung', {
      id: 'verkehrsbezeichnung',
      header: () => <span>Verkehrsbezeichnung</span>,
      cell: (info) => <p>{info.getValue()}</p>,
    }),

    columnHelper.accessor('crdate', {
      id: 'crdate',
      header: () => <span>Erstellt</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('modified', {
      id: 'modified',
      header: () => <span>Bearbeitet</span>,
      cell: (info) => {
        const timestamp = parseInt(info.getValue(), 10) * 1000; // Convert Unix timestamp to milliseconds
        const date = new Date(timestamp);

        // Format the date as "dd.mm.yy hh:mm"
        const formattedDate = `${padZero(date.getDate())}.${padZero(
          date.getMonth() + 1,
        )}.${padZero(date.getFullYear() % 100)}`;

        return <p>{formattedDate}</p>;
      },
    }),
    columnHelper.accessor('deleted', {
      id: 'deleted',
      header: () => <span></span>,
      cell: (info) => (
        <Button onClick={() => unDelete(info.row.original.id)}>
          Wiederherstellen
        </Button>
      ),
    }),
  ];

  // Helper function to pad zeroes for single-digit numbers
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  // fetch database content
  useEffect(() => {
    getRezepte({
      showDeleted: tableConfig.showDeleted,
      batchingParameters: batchingParameters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFlag, getRezepte, tableConfig.showDeleted, batchingParameters]);

  // attatch rezepte to tableConfig when finished loading
  useEffect(() => {
    const columns = tableConfig.showDeleted ? COLUMNS_DELETED : COLUMNS;
    setTableConfig({
      ...tableConfig,
      data: rezepte,
      COLUMNS: columns,
      totalPages: totalPages,
    });
    if (
      batchingParameters &&
      batchingParameters.currentListing !== currentListing
    ) {
      resetBatchingParameters({ currentListing: currentListing });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rezepte, tableConfig.showDeleted]);

  const toggleShowDeleted = () => {
    if (permissions.includes(1)) {
      setTableConfig({
        ...tableConfig,
        showDeleted: !tableConfig.showDeleted,
      });
      resetBatchingParameters({ currentListing: currentListing });
      setRefreshFlag(!refreshFlag);
    }
  };

  const unDelete = (id) => {
    if (permissions.includes(1)) {
      updateRezept(id, { deleted: 0 });
      getRezepte({
        showDeleted: true,
        batchingParameters: batchingParameters,
      });
      setRefreshFlag(!refreshFlag);
    }
  };

  if (error) {
    return <Error error={error} />;
  }
  // check if user is logged in
  if (!token) {
    setError({ status: 401 });
  }
  // check if user has the sufficient permissions to view this page
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    setError({ status: 401 });
  }

  return (
    (rezepte && (
      <div className="view-wrapper">
        <Helmet title="Rezepte" />
        <NavHeader permissions={permissions} />
        <Container className="controlpanel">
          <Segment.Group raised>
            <Segment className="primary">
              <div className="rezeptdatenbank header">
                <div className="header-container">
                  <h1>
                    {tableConfig.showDeleted ? 'Gelöschte Rezepte' : 'Rezepte'}{' '}
                  </h1>
                </div>
                <div className="button-container">
                  <div className="buttonArea">
                    {showAddButton && (
                      <Link to="rezepte/add">
                        <Button className="rdb-new" primary type="New">
                          Neues Rezept
                        </Button>
                      </Link>
                    )}
                    {showShowDeletedButton && (
                      <Button
                        className="rdb-show-deleted"
                        primary
                        onClick={() => toggleShowDeleted()}
                      >
                        {deletedButton}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Segment>
            {rezepte && tableConfig.data && (
              <Segment>
                <Filters
                  filters={[
                    'status',
                    'intern',
                    'ersteller',
                    'verkaufsinfos',
                    'rezeptgruppen',
                    'rezeptkategorien',
                    'verkaufsinformation',
                  ]}
                  filterActions={{
                    rezeptgruppen: getRezeptgruppen,
                    rezeptkategorien: getRezeptkategorien,
                  }}
                  batchingParameters={batchingParameters}
                />

                <BasicTable
                  tableConfig={tableConfig}
                  setTableConfig={setTableConfig}
                  batchingParameters={batchingParameters}
                  setBatchingParameters={setBatchingParameters}
                />
              </Segment>
            )}
          </Segment.Group>
        </Container>
        {isClient && (
          <Portal node={document.getElementById('toolbar')}>
            <Toolbar
              pathname={pathname}
              hideDefaultViewButtons
              inner={
                <Link to="/controlpanel/rezeptdatenbank" className="item">
                  <Icon
                    name={backSVG}
                    className="contents circled"
                    size="30px"
                    title={intl.formatMessage(messages.back)}
                  />
                </Link>
              }
            />
          </Portal>
        )}
      </div>
    )) || (
      <Dimmer active>
        <Loader />
      </Dimmer>
    )
  );
};

RezepteListing.propTypes = {
  pathname: PropTypes.string.isRequired,
  rezepte: PropTypes.array.isRequired,
  totalPages: PropTypes.any.isRequired,
  getRezepte: PropTypes.func.isRequired,
  updateRezept: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
  setBatchingParameters: PropTypes.func,
  resetBatchingParameters: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      rezepte: state.rezepte.data.rezepte,
      totalPages: state.rezepte.data.total_pages,
      token: state.userSession.token,
      permissions: state.permissions.data,
      batchingParameters: state.batchingParameters,
    }),
    {
      getRezepte,
      updateRezept,
      getVistPermissions,
      setBatchingParameters,
      resetBatchingParameters,
    },
  ),
)(RezepteListing);
