import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { setBatchingParameters } from '../../actions';

const CategoriesOrGroupsFilter = ({
  filterType,
  getOptions,
  title,
  applyFilter,
  batchingParameters,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptions({ getAll: true }));
  }, []);

  const options = useSelector((state) =>
    state[filterType]?.data?.[filterType]?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    }),
  );

  return (
    <Select
      className="filter-dropdown"
      placeholder={title}
      options={options}
      onChange={(selection) => applyFilter(filterType, selection)}
      value={batchingParameters?.filters[filterType] || []}
      isMulti
      isClearable
    />
  );
};

const Filters = ({ filters, filterActions, batchingParameters }) => {
  const dispatch = useDispatch();

  const applyFilter = (filter_type, filter_values) => {
    dispatch(
      setBatchingParameters({
        ...batchingParameters,
        filters: {
          ...batchingParameters?.filters,
          [filter_type]: filter_values,
        },
      }),
    );
  };

  const StatusFilter = () => {
    const statusFilters = [
      { value: '1', label: 'Aktuell' },
      { value: '3', label: 'In Bearbeitung' },
      { value: '2', label: 'Stillgelegt' },
    ];

    return (
      <Select
        className="filter-dropdown"
        options={statusFilters}
        onChange={(selection) => applyFilter('status', selection)}
        value={batchingParameters.filters.status || []}
        placeholder="Status"
        isClearable
      />
    );
  };

  const ErstellerFilter = (props) => {
    const ersteller = useSelector((state) =>
      state[batchingParameters?.currentListing]?.data?.available_authors?.map(
        (item) => {
          return {
            value: item.id,
            label: item.username,
          };
        },
      ),
    );

    return (
      <Select
        className="filter-dropdown"
        placeholder="Ersteller"
        options={ersteller}
        onChange={(selection) => applyFilter('ersteller', selection)}
        value={batchingParameters.filters.ersteller || []}
        isMulti
        isClearable
      />
    );
  };

  const filterMap = {
    status: <StatusFilter />,
    intern: (
      <Select
        className="filter-dropdown"
        options={[
          {
            value: 0,
            label: 'extern',
          },
          {
            value: 1,
            label: 'intern',
          },
        ]}
        onChange={(selection) => applyFilter('intern', selection)}
        value={batchingParameters.filters.intern || []}
        placeholder="Extern/intern"
        isClearable
      />
    ),
    ersteller: <ErstellerFilter />,
    rezeptgruppen: (
      <CategoriesOrGroupsFilter
        filterType="rezeptgruppen"
        getOptions={filterActions?.['rezeptgruppen']}
        title="Rezeptgruppen"
        batchingParameters={batchingParameters}
        applyFilter={applyFilter}
      />
    ),
    rezeptkategorien: (
      <CategoriesOrGroupsFilter
        filterType="rezeptkategorien"
        getOptions={filterActions?.['rezeptkategorien']}
        title="Rezeptkategorien"
        batchingParameters={batchingParameters}
        applyFilter={applyFilter}
      />
    ),
    produktkategorien: (
      <CategoriesOrGroupsFilter
        filterType="produktkategorien"
        getOptions={filterActions?.['produktkategorien']}
        title="Produktkategorien"
        batchingParameters={batchingParameters}
        applyFilter={applyFilter}
      />
    ),
    verkaufsinformation: (
      <Select
        className="filter-dropdown"
        options={[
          {
            value: 1,
            label: 'Ja',
          },
          {
            value: 0,
            label: 'Nein',
          },
        ]}
        onChange={(selection) => applyFilter('verkaufsinformation', selection)}
        value={batchingParameters.filters.verkaufsinformation || []}
        placeholder="Hat Verkaufsinformation"
        isClearable
      />
    ),
    unv_naehr: (
      <Select
        className="filter-dropdown"
        options={[
          {
            value: 1,
            label: 'Ja',
          },
          {
            value: 0,
            label: 'Nein',
          },
        ]}
        onChange={(selection) => applyFilter('unv_naehr', selection)}
        value={batchingParameters.filters.unv_naehr || []}
        placeholder="Unvollständige Nährwerte"
        isClearable
      />
    ),
  };

  return (
    <div className="filters">{filters.map((filter) => filterMap[filter])}</div>
  );
};

export default Filters;
