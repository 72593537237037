import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getProduktByID,
  deleteProdukt,
  getVistPermissions,
} from '../../../actions';
import NavHeader from '../NavHeader';
import ButtonArea from '../ButtonArea';
import MetaFooter from '../MetaFooter';
import NutrientInfo from '../NutrientInfo';
import NextPreviousButtons from '../NextPreviousButtons';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const ProdukteDetails = ({
  pathname,
  produkt,
  getProduktByID,
  deleteProdukt,
  intl,
  token,
  permissions = null,
  getVistPermissions,
  produkte_list,
  batching_params,
}) => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [errorDetail, setErrorDetail] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1, 13, 14, 15, 2, 4, 6];
  // Define the allowed user groups for editing Produkte / Gebinde and adding new gebinde
  const showEditButton = [1, 13, 14, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // Define the allowed user groups to show delete button
  const showDeleteButton = [1, 13, 4, 6].some((group) =>
    permissions.includes(group),
  );
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  const searchParams = new URLSearchParams(location?.search);
  const id = searchParams.get('id');

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      setIsClient(true);

      getProduktByID(id);
    }
  }, [getProduktByID, id]);

  // if (error) {
  //   return <Error error={error} />;
  // }

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    try {
      await deleteProdukt(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error.name);
      setErrorDetail(error.message);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }

  return (
    <div className="view-wrapper">
      <Helmet title="Produkte" />
      <Container className="controlpanel">
        <NavHeader permissions={permissions} />
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {produkt && produkt.bezeichnung} </h1>
              </div>
              <div className="button-container">
                <NextPreviousButtons
                  currentItem={produkt.id}
                  itemlist={produkte_list.produkte}
                  totalPages={produkte_list.total_pages}
                  batching_params={batching_params}
                />
                <ButtonArea
                  isDetails={true}
                  id={produkt.id}
                  handleDelete={handleDelete}
                  backURL={'/controlpanel/rezeptdatenbank/produkte'}
                  showDeleteButton={showDeleteButton}
                  showEditButton={showEditButton}
                  typeString={'das Produkt'}
                />
              </div>
            </div>
          </Segment>
          <Segment>
            {error && (
              <div className="error-message">
                <h2>{error}</h2>
                <p>{errorDetail}</p>
              </div>
            )}
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Produkt wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/produkte"
                >
                  Zurück zur Produkte-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                {produkt && (
                  <div className="produkte details-container">
                    <div className="meta-info">
                      <p>
                        <strong>Bezeichnung:</strong> {produkt.bezeichnung}
                      </p>
                      <p>
                        <strong>Produktnummer:</strong> {produkt.produktnummer}
                      </p>
                      {produkt && produkt?.kategorien_names?.length > 0 && (
                        <div className="kategories">
                          <strong>Produktkategorien:</strong>
                          <div className="kategory-names">
                            {produkt.kategorien_names.map((kategorie) => {
                              return <>{kategorie}, </>;
                            })}
                          </div>
                        </div>
                      )}
                      <p>
                        <strong>Intern/Extern:</strong>{' '}
                        {produkt.intern_extern ? 'intern' : 'extern'}
                      </p>
                      <p>
                        <strong>Status:</strong>{' '}
                        {produkt.status === 1
                          ? 'aktuell'
                          : produkt.status === 2
                          ? 'stillgelegt'
                          : produkt.status === 3
                          ? 'in Bearbeitung'
                          : produkt.status}
                      </p>
                    </div>
                    <NutrientInfo data={produkt} />
                    <div className="trenner" />
                    {produkt.gebinde && (
                      <div className="gebinde-list">
                        <div className="gebinde-header">
                          <strong>Gebinde:</strong>
                          <strong>Artikelnummer:</strong>
                          <strong>Zugabe in %:</strong>
                        </div>
                        {produkt.gebinde.map((gebinde) => (
                          <div key={gebinde.id} className="gebinde-entry">
                            <p>{gebinde.name}</p>
                            <p>{gebinde.artikelnummer}</p>
                            <p>{gebinde.zugabe}</p>
                            {showEditButton && (
                              <Link to={`gebinde/edit?id=${gebinde.id}`}>
                                <Button> bearbeiten</Button>
                              </Link>
                            )}
                          </div>
                        ))}
                        {showEditButton && (
                          <Button
                            className="rdb-new"
                            primary
                            type="New"
                            as={Link}
                            to={`gebinde/add?id=${produkt.id}`}
                          >
                            Gebinde anlegen
                          </Button>
                        )}
                      </div>
                    )}{' '}
                    <div className="trenner" />
                    <div className="info-texts">
                      <p>
                        <strong>
                          Alle Nährwerteangaben beziehen sich auf 100 g.
                        </strong>
                      </p>
                      <div className="info-text-box">
                        <strong>Produktbeschreibung (kurz):</strong>{' '}
                        <pre className="text-area">
                          {produkt.produktbeschreibung_kurz}
                        </pre>
                      </div>
                      <div className="info-text-box">
                        <strong>Produktbeschreibung (lang):</strong>
                        <div
                          className="text-area rich-text"
                          dangerouslySetInnerHTML={{
                            __html: produkt.produktbeschreibung_lang,
                          }}
                        />
                      </div>
                      <div className="info-text-box">
                        <strong>Zusatzinformationen:</strong>{' '}
                        <pre className="text-area">
                          {produkt.zusatzinformationen}
                        </pre>
                      </div>
                    </div>
                    <MetaFooter data={produkt} />
                  </div>
                )}
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

ProdukteDetails.propTypes = {
  pathname: PropTypes.string.isRequired,
  produkt: PropTypes.object.isRequired,
  getProduktByID: PropTypes.func.isRequired,
  deleteProdukt: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      produkt: state.produktByID.data,
      token: state.userSession.token,
      permissions: state.permissions.data,
      produkte_list: state.produkte.data,
      batching_params: state.batchingParameters,
    }),
    { getProduktByID, deleteProdukt, getVistPermissions },
  ),
)(ProdukteDetails);
