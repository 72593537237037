import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import {
  Container,
  Segment,
  Form,
  Button,
  Message,
  Dropdown,
} from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  updateBenutzer,
  getBenutzerByID,
  deleteBenutzer,
  checkBenutzer,
  getGruppen,
  getVistPermissions,
  getPloneVistUsers,
} from '../../../actions';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const BenutzerEdit = ({ intl }) => {
  const [formData, setFormData] = useState({
    loginname: '',
    vorname: '',
    nachname: '',
    userlevel: '',
    aktiv: '',
    plone_user: '',
    gruppen: [],
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [isLoginnameDuplicate, setIsLoginnameDuplicate] = useState(false);
  const [selectedGruppen, setSelectedGruppen] = useState([]);

  const history = useHistory();

  const pathname = useSelector((state) => state.router.location.pathname);
  const benutzer = useSelector((state) => state.benutzerByID.data);
  const gruppen = useSelector((state) => state.gruppen.data.gruppen);
  const token = useSelector((state) => state.userSession.token);
  const permissions = useSelector((state) => state.permissions.data);
  const vist_users = useSelector((state) => state.ploneVistUsers);

  const gruppenOptions = gruppen.map((gruppe) => ({
    key: gruppe.id,
    text: gruppe.name,
    value: gruppe.id,
  }));
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      dispatch(getVistPermissions())
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    dispatch(getBenutzerByID(id)).catch((error) => {
      setError('Error fetching benutzer data:', error.message);
    });
    dispatch(getGruppen({ getAll: true }));
  }, []);

  useEffect(() => {
    if (benutzer) {
      setFormData({
        loginname: benutzer.loginname,
        vorname: benutzer.vorname,
        nachname: benutzer.nachname,
        userlevel: benutzer.userlevel,
        aktiv: benutzer.aktiv,
        gruppen: benutzer.gruppen,
        plone_user: benutzer.plone_id,
      });
      setSelectedGruppen(benutzer.gruppen);
    }
  }, [benutzer]);

  useEffect(() => {
    if (benutzer.id) {
      dispatch(getPloneVistUsers(benutzer.plone_id));
    }
  }, [benutzer.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === 'loginname' && value !== benutzer.loginname) {
      dispatch(checkBenutzer(name, value))
        .then((result) => {
          if (result === true) {
            setIsLoginnameDuplicate(true);
          } else {
            setIsLoginnameDuplicate(false);
          }
        })
        .catch((error) => {
          setError(error.name);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    dispatch(updateBenutzer(id, formData)).then(() => {
      history.push(`/controlpanel/rezeptdatenbank/benutzer/details?id=${id}`);
    });

    // Redirect to the list of Benutzer or show success message
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    try {
      await dispatch(deleteBenutzer(id));
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error.name);
    }
  };

  if (!token) {
    return <Error error={{ status: 401 }} />;
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }
  console.log(benutzer.plone_id);
  return (
    <div className="view-wrapper">
      <Helmet title="Benutzer Bearbeiten" />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header"></div>
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {benutzer && benutzer.loginname} </h1>
              </div>
              <div className="button-container">
                <ButtonArea
                  handleDelete={handleDelete}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={
                    isLoginnameDuplicate || formData.loginname === ''
                  }
                  typeString={'den Benutzer'}
                />
              </div>
            </div>
          </Segment>
          <Segment>
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Benutzer wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/benutzer"
                >
                  Zurück zur Benutzer-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                {error && (
                  <div className="error-message">
                    <h2>{error}</h2>
                    {/* <p>{errorDetail}</p> */}
                  </div>
                )}
                <div className="rdb edit form benutzer">
                  <Form onSubmit={handleSubmit}>
                    <div className="meta-info">
                      <Form.Field error={isLoginnameDuplicate}>
                        <label>
                          <strong>Benutzername (*)</strong>
                        </label>
                        <input
                          type="text"
                          name="loginname"
                          value={formData.loginname}
                          onChange={handleInputChange}
                          required
                        />
                      </Form.Field>
                      {isLoginnameDuplicate && (
                        <div className="speech-bubble warning">
                          Loginname ist bereits vergeben
                        </div>
                      )}
                      <Form.Field>
                        <label>
                          <strong>Plone Benutzer (*):</strong>
                        </label>
                        <Dropdown
                          placeholder="Plone Nutzer Auswahl"
                          fluid
                          selection
                          clearable
                          loading={vist_users.loading}
                          options={[
                            ...vist_users.data.map((user) => {
                              return { key: user, text: user, value: user };
                            }),
                            {
                              key: '0',
                              text: 'Kein Plone Nutzer',
                              value: 'none',
                            },
                          ]}
                          value={formData.plone_user}
                          onChange={(e, { value }) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              plone_user: value,
                            }));
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Gruppen:</label>
                        <Dropdown
                          placeholder="Select Gruppen"
                          fluid
                          multiple
                          selection
                          options={gruppenOptions}
                          value={selectedGruppen}
                          onChange={(e, { value }) => {
                            setSelectedGruppen(value);
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              gruppen: value,
                            }));
                          }}
                        />
                      </Form.Field>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

BenutzerEdit.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(BenutzerEdit);
