/**
 * Benutzer actions.
 * @module actions/Benutzer
 */

import {
  GET_BENUTZER,
  GET_BENUTZERBYID,
  CREATE_BENUTZER,
  UPDATE_BENUTZER,
  DELETE_BENUTZER,
  CHECK_BENUTZER,
  GET_PLONE_VIST_USERS,
} from '../../constants/ActionTypes';

/**
 * Get Produkt items.
 * @function getBenutzer
 * @param {showDeltetd} show delted items
 * @returns {Object} Produkt action.
 */
export function getBenutzer({ showDeleted = false, batchingParameters = {} }) {
  const {
    limit = 10,
    skip = 0,
    sort_by = '',
    asc = false,
    searchTerms = '',
  } = batchingParameters;

  // Initialize an empty array to store query parameters
  const queryParams = [];
  if (sort_by) {
    queryParams.push(`sort_by=${sort_by}`);
  }
  if (searchTerms) {
    queryParams.push(`search=${searchTerms}`);
  }
  // Construct the pathSuffix by joining query parameters with '&'
  let pathSuffix = `?limit=${limit}&skip=${skip}&asc=${asc}`;
  if (queryParams.length > 0) {
    pathSuffix += `&${queryParams.join('&')}`;
  }
  if (showDeleted) {
    return {
      type: GET_BENUTZER,
      request: {
        op: 'get',
        path: `/@benutzer/deleted${pathSuffix}`,
      },
    };
  } else {
    return {
      type: GET_BENUTZER,
      request: {
        op: 'get',
        path: `/@benutzer${pathSuffix}`,
      },
    };
  }
}

/**
 * Get Benutzer item by ID.
 * @function getBenutzerByID
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function getBenutzerByID(id) {
  return {
    type: GET_BENUTZERBYID,
    request: {
      op: 'get',
      path: `/@benutzer/${id}`,
    },
  };
}

/**
 * Create a new Benutzer.
 * @function createBenutzer
 * @param {Object} formData The data of the new Benutzer
 * @returns {Object} Benutzer action.
 */
export function createBenutzer(formData) {
  return {
    type: CREATE_BENUTZER,
    request: {
      op: 'post',
      path: '/@benutzer',
      data: formData,
    },
  };
}

/**
 * Create a new Benutzer.
 * @function getPloneVistUsers
 * @returns {Object} Benutzer action.
 */
export function getPloneVistUsers(plone_user) {
  console.log(plone_user);
  return {
    type: GET_PLONE_VIST_USERS,
    request: {
      op: 'get',
      path: plone_user
        ? `/@benutzer_plone_users?user=${plone_user}`
        : '/@benutzer_plone_users',
    },
  };
}

/**
 * Update a Benutzer.
 * @function updateBenutzer
 * @param {string} id Benutzer id
 * @param {Object} formData The data to update the Benutzer
 * @returns {Object} Benutzer action.
 */
export function updateBenutzer(id, formData) {
  return {
    type: UPDATE_BENUTZER,
    request: {
      op: 'patch',
      path: `/@benutzer/${id}`,
      data: formData,
    },
  };
}

/**
 * Delete a Benutzer.
 * @function deleteBenutzer
 * @param {string} id Benutzer id
 * @returns {Object} Benutzer action.
 */
export function deleteBenutzer(id) {
  return {
    type: DELETE_BENUTZER,
    request: {
      op: 'del',
      path: `/@benutzer/${id}`,
    },
  };
}

/**
 * Check Benutzer for specific value in specific field.
 * @function checkBenutzer
 * @param {string} field, specified field
 * @param {string} value, specified value
 * @returns {Object} Benutzer action.
 */
export function checkBenutzer(field, value) {
  return {
    type: CHECK_BENUTZER,
    request: {
      op: 'get',
      path: `/@benutzer_check/${field}=${value}`,
    },
  };
}
