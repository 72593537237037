import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { some } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Portal } from 'react-portal';
import { Helmet } from '@plone/volto/helpers';
import { Container, Segment, Button, Message } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { Error, Icon, Toolbar } from '@plone/volto/components';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getBenutzerByID,
  deleteBenutzer,
  getGruppen,
  getVistPermissions,
} from '../../../actions';
import NavHeader from '../NavHeader';
import ButtonArea from '../ButtonArea';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
});

const BenutzerDetails = ({
  pathname,
  benutzer,
  getBenutzerByID,
  deleteBenutzer,
  getGruppen,
  gruppen = [],
  intl,
  token,
  permissions = null,
  getVistPermissions,
}) => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // Define the allowed user groups for the whole view
  const allowedUserGroups = [1];
  // flag to make sure permissions are fully loaded before returning unauthorized error
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  // load permissions
  useEffect(() => {
    setIsClient(true);
    // Check if permissions are available, if not, fetch them
    if (!permissions.length && permissionsLoading) {
      getVistPermissions()
        .then(() => {
          setPermissionsLoading(false);
        })
        .catch((error) => {
          setError({ status: 401 });
          setPermissionsLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setIsClient(true);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    getGruppen({ getAll: true });
    getBenutzerByID(id);
  }, [getBenutzerByID, getGruppen]);

  if (error) {
    return <Error error={error} />;
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const formattedDate = date.toLocaleDateString('ger-DE', {
      hour: 'numeric',
      minute: 'numeric',
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });
    return formattedDate;
  };

  const handleDelete = async () => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    try {
      await deleteBenutzer(id);
      setDeleteSuccess(true);
    } catch (error) {
      // Handle delete error
      setError(error);
    }
  };

  if (!token) {
    setError({ status: 401 });
  }
  if (
    !permissionsLoading &&
    !some(allowedUserGroups, (group) => permissions.includes(group))
  ) {
    return <Error error={{ status: 401 }} />;
  }

  return (
    <div className="view-wrapper">
      <Helmet title="Benutzer" />
      <NavHeader permissions={permissions} />
      <Container className="controlpanel">
        <Segment.Group raised>
          <Segment className="primary">
            <div className="rezeptdatenbank header">
              <div className="header-container">
                <h1> {benutzer && benutzer.loginname} </h1>
              </div>
              <div className="button-container">
                {!deleteSuccess && (
                  <ButtonArea
                    isDetails={true}
                    handleDelete={() => handleDelete()} // todo Implement Handle Delete
                    id={benutzer.id}
                    backURL={'/controlpanel/rezeptdatenbank/benutzer'}
                    typeString={'den Benutzer'}
                  />
                )}
              </div>
            </div>
          </Segment>
          <Segment>
            {deleteSuccess ? (
              <Message positive>
                <Message.Header>Benutzer wurde gelöscht</Message.Header>
                <Button
                  primary
                  as={Link}
                  to="/controlpanel/rezeptdatenbank/benutzer"
                >
                  Zurück zur Benutzer-Übersicht
                </Button>
              </Message>
            ) : (
              <>
                {' '}
                {benutzer && (
                  <div className="details-container">
                    <div className="meta-info">
                      <p>
                        <strong>Benutzername:</strong> {benutzer.loginname}
                      </p>
                      {benutzer && benutzer?.gruppen?.length > 0 && gruppen && (
                        <div className="kategories">
                          <strong>Gruppen:</strong>
                          <div className="kategory-names">
                            {gruppen
                              .filter((gruppe) =>
                                benutzer?.gruppen.includes(gruppe.id),
                              )
                              .map((gruppe) => {
                                return <>{gruppe.name} </>;
                              })}
                          </div>
                        </div>
                      )}
                      <p>
                        <strong>Erstellt:</strong>{' '}
                        {formatDateTime(benutzer.crdate)}
                      </p>
                      <p>
                        <strong>Bearbeitet:</strong>{' '}
                        {formatDateTime(benutzer.modified)}
                      </p>
                      <p>
                        <strong>Verknüpfter Plone user:</strong>
                        {benutzer.plone_id}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </Segment>
        </Segment.Group>
      </Container>
      {isClient && (
        <Portal node={document.getElementById('toolbar')}>
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to="/controlpanel/rezeptdatenbank" className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />
        </Portal>
      )}
    </div>
  );
};

BenutzerDetails.propTypes = {
  pathname: PropTypes.string.isRequired,
  benutzer: PropTypes.object.isRequired,
  getBenutzer: PropTypes.func.isRequired,
  deleteBenutzer: PropTypes.func.isRequired,
  gruppen: PropTypes.array.isRequired,
  getGruppen: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  token: PropTypes.string,
  permissions: PropTypes.array,
  getVistPermissions: PropTypes.func,
};

export default compose(
  injectIntl,
  connect(
    (state) => ({
      pathname: state.router.location.pathname,
      benutzer: state.benutzerByID.data,
      gruppen: state.gruppen.data.gruppen,
      token: state.userSession.token,
      permissions: state.permissions.data,
    }),
    { getBenutzerByID, deleteBenutzer, getGruppen, getVistPermissions },
  ),
)(BenutzerDetails);
